
export class PartnerOrder {
  partner: any;
  orderItem: {
    product: any,
    quantity: number
  }[];
  shippedDate?: any;
  orderStatus?: string;
  deliveryFee?: number;
  orderAmount?: number;
  discountAmount?: number;
  expectedPickupTime?: string;
  expectedDeliveryDate?: Date;
  expectedDeliveryTime?: string;
} 
