import { Partner } from 'src/app/admin/pages/partner/partner';
import { Order } from 'src/app/admin/pages/order/order';
import { MailType } from 'src/app/shared/classes/mailType';

export class Mail {
  from: string = "orders@emabhansini.co.za";
  to: string;
  subject: string;
  body?: string;
  order: any;
  orderNumber: string;
  mailType: string;

  static getCustomerNotifcation(order: Order, orderNum: string) {
    let mail = new Mail();

    mail.to = order.customer.email;
    mail.subject = 'Order Confirmation #' + orderNum;
    mail.order = order;
    mail.mailType = MailType.Customer;
    mail.orderNumber = orderNum;

    return mail;
  }

  static getProductOwnerNotifcation(order: Order, orderNum: string, partnerEmail: string) {
    let mail = new Mail();

    mail.to = partnerEmail;
    mail.subject = 'New Order #' + orderNum;
    mail.order = order;
    mail.mailType = MailType.Owner;
    mail.orderNumber = orderNum;

    return mail;
  }

  static getOrderUpdateNotification(order: Order, orderNum: string){
    let mail = new Mail();

    mail.to = order.customer.email;
    mail.subject = 'Order Update #' + orderNum;
    mail.order = order;
    mail.mailType = MailType.OrderUpdate;
    mail.orderNumber = orderNum;
    return mail;
  }

  static getCompleteSignupNotification(partner: Partner){
    let mail = new Mail();

    mail.from = "info@emabhansini.co.za";
    mail.to = partner.email;
    mail.subject = 'Welcome to Emabhansini | Signup';
    mail.mailType = MailType.Signup;

    return mail;
  }
}
