const ROLE_ADMIN = 'Admin';
const ROLE_USER = 'user';
const ROLE_BAKER = 'Baker';
const ROLE_DRIVER = 'Driver';
const ROLE_SUPER_USER = 'superUser';
const ROLE_ADMIN_ID = 'rol_12dPl1yofgqjP8yJ';
const ROLE_BAKER_ID = 'rol_CWa9s0zdPJMiByZ8';
const ROLE_USER_ID = 'rol_NvS9VUTDbEKTbxac';


export const ROLE = {
    ADMIN: ROLE_ADMIN,
    USER: ROLE_USER,
    SUPER_USER: ROLE_SUPER_USER,
    BAKER: ROLE_BAKER,
    DRIVER: ROLE_DRIVER,
    ADMIN_ID: ROLE_ADMIN_ID,
    BAKER_ID: ROLE_BAKER_ID,
    USER_ID: ROLE_USER_ID
};