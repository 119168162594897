import { VerificationService } from './../../shared/modules/verification/verification.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AuthService } from '@auth0/auth0-angular';
import { Partner } from 'src/app/admin/pages/partner/partner';
import { PartnerService } from 'src/app/admin/pages/partner/partner.service';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { ProfileService } from '../profile/profile.service';
import { Router } from '@angular/router';
import { MailService } from 'src/app/shared/services/mail.service';
import { concatMap } from 'rxjs/operators';
declare var $: any;

export const LOCAL_STORAGE_PARTNER = {
  PARTNER_KEY: "partner", 
  SUCCESS_ROUTE_KEY: "successRoute",
  SUCCESS_ROUTE: "/partner-registration-success",
  PHONE_NUMBER_KEY: "phoneNumber",
  TARGET_ROUTE_KEY: "targetRoute",
  TARGET_ROUTE: "/signup"
};

@Component({
  selector: 'app-partner-reg',
  templateUrl: './partner-reg.component.html',
  styleUrls: ['./partner-reg.component.css']
})
export class PartnerRegComponent implements OnInit {

  pageTitle = 'Join the Emabhansini Family | Become a Partner';
  @ViewChild('address') searchElement: ElementRef;
  fieldTextType: boolean;
  repeatFieldTextType: boolean;
  readTerms: boolean;
  partnerForm = this.fb.group({
    name: [null, Validators.required],
    surname: [null, Validators.required],
    contact: [null, [
      Validators.required,
      Validators.maxLength(10),
      Validators.pattern('[0-9]{10}'),
      Validators.minLength(10)]
    ],
    email: [null, [
      Validators.email,
      Validators.required]
    ],
    businessName: ['', Validators.required],
    addressDetail: [''],
    foodType: ['']
  })
  isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private title: Title,
    public profile: ProfileService,
    public location: LocationService,
    public preLoader: LoadingService,
    private partner: PartnerService,
    private message: MessageService,
    public verification: VerificationService,
    private _auth: AuthService, 
    private router: Router,
    private mail: MailService
  ) { }

  ngOnInit(): void {
    // Set web app tab title
    this.title.setTitle(this.pageTitle);

    // Third-part Corousal
    var owl = $('.owl-1');
    owl.owlCarousel({
      loop: false,
      margin: 0,
      nav: false,
      dots: false,
      items: 1,
      smartSpeed: 1000,
      autoplay: false,
      navText: ['<span class="icon-keyboard_arrow_left">', '<span class="icon-keyboard_arrow_right">']
    });

    var carousel_nav_a = $('.carousel-nav a');

    carousel_nav_a.each(function (slide_index) {
      var $this = $(this);
      $this.attr('data-num', slide_index);
      $this.click(function (e) {
        owl.trigger('to.owl.carousel', [slide_index, 1500]);
        e.preventDefault();
      })
    })

    owl.on('changed.owl.carousel', function (event) {
      carousel_nav_a.removeClass('active');
      $(".carousel-nav a[data-num=" + event.item.index + "]").addClass('active');
    })
  }

  ngAfterViewInit() {
    // Initialise google location API
    this.location.initGoogleLocationAPI(this.searchElement);
  }

  signup() {
    // this._auth.loginWithRedirect({
    //   authorizationParams: {
    //     screen_hint: 'signup',
    //     login_hint: this.email.value,
    //     redirect_uri: `${window.location.origin}/callback`,
    //     appState: { target: '/verification' }
    //   }
    // });

    // Set up local storage variables to pass on to verification 
    // component and retrieve post page redirect
    // localStorage.setItem(LOCAL_STORAGE_PARTNER.PHONE_NUMBER_KEY, this._formatContactNumber());
    // localStorage.setItem(LOCAL_STORAGE_PARTNER.TARGET_ROUTE_KEY, LOCAL_STORAGE_PARTNER.TARGET_ROUTE);
    // localStorage.setItem(LOCAL_STORAGE_PARTNER.SUCCESS_ROUTE_KEY, LOCAL_STORAGE_PARTNER.SUCCESS_ROUTE);
    // localStorage.setItem(LOCAL_STORAGE_PARTNER.PARTNER_KEY, JSON.stringify(this._getPartnerObject()));

    // Create partner and send email notification
    this.partner.addPartner$(this._getPartnerObject()).pipe(
      concatMap(partner => this.mail.sendSignupNotification(partner))
    ).subscribe(_ => {
      // Navigate to success page
      this.router.navigate(['registration/success'])
    });
  
  }

  onSubmit() {
    this.verification.sendVerificationCode(this._formatContactNumber());
    this.verification.setTargetRoute("/signup");
  }

  private _formatContactNumber() {
    return '+27' + this.contact.value.trim().substr(1);
  }

  private _getPartnerObject() {
   

    let p = new Partner(
      this.partnerForm.value,
      this.location.address,
      this.location.latitude,
      this.location.longitude
    );
    
    console.log(p);

    return p;
  }

  get name() {
    return this.partnerForm.get('name');
  }

  get surname() {
    return this.partnerForm.get('surname');
  }

  get email() {
    return this.partnerForm.get('email');
  }

  get contact() {
    return this.partnerForm.get('contact');
  }

  get businessName() {
    return this.partnerForm.get('businessName');
  }

  get addressDetail() {
    return this.partnerForm.get('addressDetail');
  }
}
