<!-- Banner Section Begin -->
<section class="site-section partner_banner_area">
  <div class="overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-6 px-md-5 col-12 partner-form-content">
        <h1 class="mb-3">Share your <b>passion</b> with the world</h1>
        <!-- <p class="banner-text">Join us in shaping the future of homemade delights and contributing to
          a movement that values authenticity, creativity, and the personal touch that independent
          bakers like yourself, bring to the table.
        </p> -->
        <p class="banner-text">
          Join us in shaping the future of homemade delights and celebrating the authenticity, creativity 
        and personal touch of independent bakers.
        </p>
      </div>
      <div class="col-md-6 col-12 mt-4">
        <form [formGroup]="partnerForm" novalidate (ngSubmit)="signup()" class="partner-form">
          <h2 class="mb-4">Join Our Family</h2>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group has-danger">
                <label for="name">Name</label>
                <input id="name" type="text" class="form-control" [ngClass]="{'is-invalid': name.hasError('required')}"
                  formControlName="name" [ngClass]="{'is-invalid': name.errors && (name.dirty || name.touched)}"
                  required>
                <div class="invalid-feedback" *ngIf="name.hasError('required')">
                  <strong>Required</strong> Field
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group has-danger">
                <label for="surname">Surname</label>
                <input id="surname" type="text" class="form-control"
                  [ngClass]="{'is-invalid': surname.hasError('required')}" formControlName="surname"
                  [ngClass]="{'is-invalid': surname.errors && (surname.dirty || surname.touched)}" required>
                <div class="invalid-feedback" *ngIf="surname.hasError('required')">
                  <strong>Required</strong> Field
                </div>
              </div>
            </div>


            <div class="col-12">
              <div class="form-group has-danger">
                <label for="email">Email</label>
                <input id="email" type="email" class="form-control"
                  [ngClass]="{'is-invalid': email.errors && (email.dirty || email.touched)}" formControlName="email"
                  required>
                <div class="invalid-feedback" *ngIf="email.hasError('required')">
                  <strong>Required</strong> Field
                </div>
                <div class="invalid-feedback" *ngIf="email.hasError('email')">
                  <strong>Invalid</strong> Field
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group has-danger">
                <label for="contact">Contact</label>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-default">+27</span>
                  </div>
                  <input id="contact" type="text"
                    [ngClass]="{'is-invalid': !contact.valid && (contact.dirty || contact.touched)}"
                    class="form-control" formControlName="contact" required minlength="10" maxlength="10"
                    aria-describedby="inputGroup-sizing-default">
                </div>
                <div class="invalid-feedback" *ngIf="contact.hasError('required')">
                  <strong>Required</strong> Field
                </div>
                <div class="invalid-feedback" *ngIf="contact.hasError('maxlength')">
                  <strong>Length Exceeded</strong>
                </div>
                <div class="invalid-feedback" *ngIf="contact.hasError('minlength')">
                  <strong>Invalid Length</strong>
                </div>
                <div class="invalid-feedback" *ngIf="contact.hasError('pattern')">
                  <strong>Invalid Field</strong>
                </div>
              </div>
            </div>

            <div class="col-12 mt-5">
              <div class="form-group">
                <label for="businessName">Business name</label>
                <input id="businessName" type="text" class="form-control" placeholder="" formControlName="businessName">
              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <label for="address">Business Address</label>
                <input id="address" type="text" class="form-control" placeholder="Search for Location..."
                  [(ngModel)]="location.address" [ngModelOptions]="{standalone: true}" #address name="location">
              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <label for="addressDetail">Floor / Suite (Optional)</label>
                <input id="addressDetail" type="text" class="form-control" placeholder=""
                  formControlName="addressDetail">
              </div>
            </div>

            <!-- <div class="col-12">
              <div class="form-group">
                <label for="foodType">Type of Baked Products</label>
                <select class="custom-select" multiple="" id="foodType" formControlName="foodType">
                  <option>Biscuit</option>
                  <option>Bread</option>
                  <option>Cake</option>
                  <option>Cookie</option>
                  <option>Cracker</option>
                  <option>Pastry</option>
                  <option>Pie</option>
                  <option>Tart</option>
                  <option>Torte</option>
                  <option>Viennoiserie</option>
                </select>
              </div>
            </div> -->

          </div>

          <div class="custom-control my-4">
            <input class="custom-control-input" type="checkbox" value="" id="flexCheckChecked" [checked]="readTerms"
              (change)="readTerms = !readTerms">
            <label class="custom-control-label" for="flexCheckChecked">
              By clicking 'Submit' you agree to our <a href="/terms" target="_blank">Terms of Service</a> and
              acknowledge you have read our<a href="/privacy" target="_blank">Privacy Policy</a>.
            </label>
          </div>

          <div class="row">
            <div class="col-12">
              <button class="btn btn-primary py-3 mb-4 px-4 btn-block btn-md-inline" type="submit"
                [disabled]="!partnerForm.valid || (preLoader.loading && partnerForm.valid) || !readTerms">
                <span *ngIf="!preLoader.loading">Submit</span>
                <span *ngIf="preLoader.loading">Loading...</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<!-- Banner Section End -->

<!-- Join Reasons Summary Section Begin -->
<section class="site-section">
  <div class="container">
    <h2 class="mb-3">Reasons you should join our family</h2>
    <div class="row">
      <div class="col-md-4 col-12 pr-md-5 mb-4">
        <span class="section-subheader">Monetize Your Passion</span>
        <!-- <p>Turn your love for baking into a source of income.
          Emabhansini empowers you to monetize your passion by reaching customers
          who appreciate the artistry and personal touch that comes with homemade treats.
          Earn while doing what you love, and let your creations speak for themselves.</p> -->
          <p>Turn your love for baking into a source of income.
            Emabhansini empowers you to monetize your passion by reaching customers
            who appreciate the artistry and personal touch that comes with homemade treats.
          </p>
      </div>
      <div class="col-md-4 col-12 pr-md-5 mb-4">
        <span class="section-subheader">Access To Marketing</span>
        <p>We actively promote our platform through social media, partnerships, 
          and promotional campaigns. Leverage our marketing efforts to boost your profile 
          and attract more customers to your unique offerings.
        </p>
      </div>
      <div class="col-md-4 col-12 pr-md-5 mb-4">
        <span class="section-subheader">Ordering & Delivery</span>
        <p>Our platform streamlines the ordering process, manages payments, and provides 
          convenient delivery options, ensuring a hassle-free experience for both you 
          and your customers.</p>
      </div>
    </div>
  </div>
</section>
<!-- Join Reasons Summary Section End -->

<!-- Reason 2 Section Begin -->
<section class="site-section section-background mb-5">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-12 my-auto pr-md-5">
        <span class="section-subheader">Flexibility and Control</span>
        <h2 class="mb-4">Keeping you at the center</h2>
        <!-- <p class="text-justify">Enjoy the freedom of managing your baking business on your terms.
          Set your own schedule, create your menu, and decide on pricing. Emabhansini provides
          the platform; you decide how to shape and grow your baking venture.</p> -->
        <p>Enjoy the freedom of managing your baking business on your terms.
          Set your own schedule, create your menu, and decide on pricing.</p>
      </div>
      <div class="col-md-6 col-12">
        <ngx-picture class="img-fluid"
          src="https://res.cloudinary.com/db2qfhly1/image/upload/q_auto/f_webp/c_scale,w_auto/v1706303732/emabhansini/img/how%20to/medium-shot-woman-carrying-tray-min_wvclqk.jpg"
          alt="emabhansini baker holding tray of freshly baked bread" [lazyLoad]="true">
        </ngx-picture>
      </div>
    </div>
  </div>
</section>
<!-- Reason 2 Section End -->

<!-- Reason 3 Section Begin -->
<section class="site-section">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-12 mb-4">
        <ngx-picture class="img-fluid"
          src="https://res.cloudinary.com/db2qfhly1/image/upload/q_auto/f_webp/c_scale,w_auto/v1676057175/emabhansini/women-preparing-together-romantic-dinner-home_dzemtd.jpg"
          alt="embhansini women bakers laughing in the kitchen" [lazyLoad]="true">
        </ngx-picture>
      </div>
      <div class="col-md-6 col-12 my-auto pl-md-5">
        <span class="section-subheader">It's all love</span>
        <h2 class="mb-4">Tap into a Supportive Baking Community</h2>
        <p>Emabhansini is your space to grow and thrive
          within a community that understands and values your craft.</p>
      </div>
    </div>
  </div>
</section>
<!-- Reason 3 Section End -->

<!-- Get Started Section Begin -->
<section class="site-section section-background">
  <h2 class="mb-5 text-center">3 simple steps to get started</h2>
  <div class="d-flex carousel-nav">
    <a href="#" class="col active">Sign-Up</a>
    <a href="#" class="col">Profile</a>
    <a href="#" class="col">Bake</a>
  </div>


  <div class="owl-carousel owl-1">

    <div class="media-29101 d-md-flex w-100 align-items-center">
      <div class="carousel-img">
        <ngx-picture class="img-fluid"
          src="https://res.cloudinary.com/db2qfhly1/image/upload/f_webp/c_scale,w_auto/v1706303410/emabhansini/img/how%20to/amazed-woman-holding-smartphone_i5rpby.jpg"
          alt="emabhansini partner looking excited with phone in hand" [lazyLoad]="true">
        </ngx-picture>
      </div>
      <div class="text">
        <a class="category d-block mb-2" href="#">Step 1</a>
        <h2><a href="#">Sign Up</a></h2>
        <p>Click here or fill in the sign-up form on the top of this page. Fill in your basic information,
          and choose a username that reflects your baking identity.
        </p>
      </div>
    </div>

    <div class="media-29101 d-md-flex w-100 align-items-center">
      <div class="carousel-img">
        <ngx-picture class="img-fluid"
          src="https://res.cloudinary.com/db2qfhly1/image/upload/q_auto/f_webp/c_scale,w_auto/v1706303410/emabhansini/img/how%20to/medium-shot-smiley-black-woman-running-business_h2awp1.jpg"
          alt="emabhansini women smiling with a note pad in hand" [lazyLoad]="true">
        </ngx-picture>
      </div>
      <div class="text">
        <a class="category d-block mb-2" href="#">Step 2</a>
        <h2><a href="#">Create Your Bakery Profile</a></h2>
        <p>Once signed up, complete your bakery profile by adding details such as your specialty,
          the variety of treats you offer and a captivating description of your baking journey.</p>
      </div>
    </div>

    <div class="media-29101 d-md-flex w-100 align-items-center">
      <div class="carousel-img">
        <ngx-picture class="img-fluid"
          src="https://res.cloudinary.com/db2qfhly1/image/upload/q_auto/f_webp/c_scale,w_auto/v1706303413/emabhansini/img/how%20to/attractive-young-african-american-housewife-beige-dress-standing-kitchen-with-utensils-wooden-spoon-having-pensive-facial-expression-thinking-what-cook-dinner-cuisine-food_dpm1ij.jpg"
          alt="emabhansini baker deep in thought with whisk and bowl in hand" [lazyLoad]="true">
        </ngx-picture>
      </div>
      <div class="text">
        <a class="category d-block mb-2" href="#">Step 3</a>
        <h2><a href="#">List Your Creations and Start Baking</a></h2>
        <p>With your profile set up, you can list your creations on the platform by providing detailed 
          descriptions, prices, high-quality images of your products and any customization options.</p>
      </div>
    </div>
  </div>
</section>
<!-- Get Started Section End -->

<!-- FAQ Section Begin -->
<section class="site-section">
  <div class="container mb-5">
    <h2 class="mb-5">Frequently Asked Questions</h2>
    <div class="accordion" id="accordionExample">
      <div class="card" style="border: none;">
        <div class="card-header" style="background-color: transparent;" id="headingOne">
          <h2 class="mb-0">
            <span class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
              data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              How does the ordering process work for customers on Emabhansini?
            </span>
          </h2>
        </div>

        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
          <div class="card-body">
            Customers browse through the Emabhansini platform, discover your bakery, and place orders 
            for your delightful treats. You'll receive notifications of incoming orders, and once you confirm, 
            our platform takes care of payment processing and delivery coordination. You simply focus on baking!
          </div>
        </div>
      </div>
      <div class="card" style="border: none;">
        <div class="card-header" style="background-color: transparent;" id="headingTwo">
          <h2 class="mb-0">
            <span class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
              data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              How does Emabhansini handle payments and payouts to bakers?
            </span>
          </h2>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
          <div class="card-body">
            Emabhansini manages payment processing securely. Customers pay for their orders online, 
            and once the order is fulfilled, payouts to bakers are initiated. You can set up your preferred 
            payment method in your account settings.
          </div>
        </div>
      </div>
      <div class="card" style="border: none;">
        <div class="card-header" style="background-color: transparent;" id="headingThree">
          <h2 class="mb-0">
            <span class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
              data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              Are there any fees associated with being a baker on Emabhansini?
            </span>
          </h2>
        </div>
        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
          <div class="card-body">
            Emabhansini may charge a small fee for using the platform, but this helps cover the costs 
            of managing orders, marketing, and providing support. The fee structure is transparent, 
            and you can find detailed information in your account dashboard.
          </div>
        </div>
      </div>
      <div class="card" style="border: none;">
        <div class="card-header" style="background-color: transparent;" id="headingFour">
          <h2 class="mb-0">
            <span class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
              data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              Can I offer delivery, and how is it coordinated on Emabhansini?
            </span>
          </h2>
        </div>
        <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
          <div class="card-body">
            Yes, you can offer delivery! Emabhansini coordinates the delivery process for you. 
            Clearly specify your delivery options and radius in your profile. Once an order is placed, 
            the platform manages the delivery logistics to ensure a timely and efficient service.
          </div>
        </div>
      </div>
      <div class="card" style="border: none;">
        <div class="card-header" style="background-color: transparent;" id="headingFive">
          <h2 class="mb-0">
            <span class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
              data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
              How can I get support if I encounter any issues on the platform?
            </span>
          </h2>
        </div>
        <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
          <div class="card-body">
            We're here to support you! Reach out to our customer support team by sending your support query
             to <a href="mailto:support@emabhansini.co.za">support@emabhansini.co.za</a>  We value our community and want to ensure your experience on 
             Emabhansini is smooth and enjoyable.
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- FAQ Section End -->