import { filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Product } from 'src/app/admin/pages/product/product';
import { Location } from './../../shared/classes/location';
import { CartItem } from './cart-item';
const LOCAL_STORAGE_CART: string = "cart";

@Injectable({
  providedIn: 'root'
})
export class CartService {

  cart: CartItem[] = [];
  delivery: number = 0;
  coupon: string = null;
  deliveryLocation = new Location();

  constructor(
  ) {
    if (localStorage.getItem(LOCAL_STORAGE_CART)) {
      this.cart = this.getLocalStorage();
    }
  }

  add(product: Product, quantity: number = 1) {
    // Check if there is a partner order already in the cart
    let itemIndex = this._getCartItemIndex(product.partner);

    if (itemIndex > -1) {
      // Partner exists check if product has been added to cart
      let index = this.cart[itemIndex].partnerOrder.orderItem.findIndex(c => c.product._id === product._id);

      if (index > -1) {
        // Product exists increment quantity
        this.cart[itemIndex].partnerOrder.orderItem[index].quantity++;
      } else {
        // Product does not exist add product onto partner order
        this.cart[itemIndex].partnerOrder.orderItem.push({
          product: product,
          quantity: quantity
        });
      }

    } else {

      // Partner does not exist create new partner order
      // Create new cart item
      let cartItem = new CartItem();
      cartItem.partnerOrder.orderItem = [{
        product: product,
        quantity: quantity
      }];
      cartItem.partnerOrder.partner = product.partner;

      // Set partner order amount
      cartItem.partnerOrder.orderAmount = this.partnerOrderAmount(cartItem.partnerOrder.orderItem);

      // Add to cart
      this.cart.push(cartItem);
    }

    // Save cart to browser local storage
    this._setLocalStorage(this.cart);
  }

  remove(orderItem) {
    this.cart.map((order, index) => {
      // Remove orderItem by filtering it out from the orderitems array
      order.partnerOrder.orderItem = order.partnerOrder.orderItem.filter(item => item.product._id !== orderItem.product._id);

      // Update partner order amount
      order.partnerOrder.orderAmount = this.partnerOrderAmount(order.partnerOrder.orderItem);

      // If partner order has no more item delete partner order
      if (!order.partnerOrder.orderItem.length) {
        this.cart.splice(index, 1);
      }
    })

    // // Sync browser local storage on current cart state
    this._setLocalStorage(this.cart);
  }

  reduceQuantity(product: Product) {
    this.cart.map((order, index) => {
      let itemIndex = order.partnerOrder.orderItem.findIndex(item => item.product._id === product._id);
      let item = order.partnerOrder.orderItem[itemIndex];

      // Remove or reduce quantity ordered based on current quantity
      if (item.quantity = 1) {
        this.remove(item);
        // If there are no more products in 
        // the partner's order remove parrtner order
        if (order.partnerOrder.orderItem.length < 1) {
          this.cart.splice(index, 1);
        }
      } else {
        order.partnerOrder.orderItem[itemIndex].quantity--;
      }
    })

    // Sync browser local storage on current cart state
    this._setLocalStorage(this.cart);
  }

  resetCart() {
    this.cart = [];
    this._deleteLocalStorage();
  }

  countAll() {
    // Return sum of items in cart based on quantity
    return this.getOrderItems().reduce((a, b) => a + (b.quantity), 0);
  }

  count(prooductId) {
    // Filter orderItems by productId and sum quantity
    return this.getOrderItems().filter(item => item.product._id === prooductId)
      .reduce((a, b) => a + (b.quantity), 0);
  }

  itemTotal(orderItem) {
    return orderItem.quantity * orderItem.product.price;
  }

  partnerOrderAmount(orderItems) {
    return orderItems.reduce((a, b) => a + (this.itemTotal(b)), 0);
  }

  cartTotal() {
    let total = +((this.discountedSubTotal() + this.getTotalDeliveryFee()).toFixed(2));
    return total;
  }

  discountedSubTotal() {
    let total = +((this.subTotal() - this.discount()).toFixed(2));
    return total;
  }

  getTotalDeliveryFee() {
    return this.cart.reduce((a, b) => a + (b.partnerOrder.deliveryFee), 0);
  }

  discount() {
    if (this.applyDiscount(this.coupon)) {
      return +((this.subTotal() * 0.2)).toFixed(2);
    } else {
      return 0;
    }
  }

  getLocalStorage(): CartItem[] {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_CART));
  }

  applyDiscount(coupon: string) {
    let couponLower = '';

    if (coupon) {
      couponLower = coupon.toUpperCase();
    }

    return couponLower === "AMABHANS"; //TODO: Pull from Product
  }

  subTotal() {
    return this.getOrderItems().reduce((a, b) => a + (this.itemTotal(b)), 0);
  }

  isMultipleOwners() {
    // Return last entry
    let res = this.cart.map((item, index, arr) => {
      const prevItem = index === 0 ? arr[index] : arr[index - 1];
      return (item.partnerOrder.partner._id !== prevItem.partnerOrder.partner._id)
    }).slice(-1)

    return res[0];
  }

  getOrderItems() {
    return this.cart.reduce((prev, next) => prev.concat(next.partnerOrder.orderItem), []);
  }

  private _getCartItemIndex(partner) {
    return this.cart.findIndex(item => item.partnerOrder.partner._id === partner._id);
  }

  private _deleteLocalStorage() {
    localStorage.removeItem(LOCAL_STORAGE_CART);
  }

  private _setLocalStorage(cart: CartItem[]) {
    // Clear previous cart object before setting current cart state
    if (localStorage.getItem(LOCAL_STORAGE_CART)) {
      this._deleteLocalStorage();
    }

    localStorage.setItem(LOCAL_STORAGE_CART, JSON.stringify(cart));
  }
}
