import { io } from "socket.io-client";
const _isDev = window.location.port.indexOf('4200') > -1 || window.location.port.indexOf('8080')  > -1;
const getHost = () => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    return `${protocol}//${host}`;
};
// const apiURI = _isDev ? 'https://localhost:44325/api/' : `/api/`;
// const apiURI = _isDev ? 'http://localhost:8083/api/' : `https://us-central1-emabhansini.cloudfunctions.net/app/api/`;
const apiURI = _isDev ? 'http://localhost:5001/api/' : 'https://emabhansini.herokuapp.com/api/';
// const apiURI = 'http://localhost:5001/api/';
const oauthApiURI = 'https://digispect.auth0.com/oauth/token';
const mgmtApiURI = 'https://dev-egua4bns.eu.auth0.com/api/v2/';
const socketURI = io(apiURI.slice(0, -5), { autoConnect: false });

export const ENV = {
    BASE_URI: getHost(), 
    BASE_API: apiURI,   
    OAUTH_API: oauthApiURI,
    USER_MGMT_API: mgmtApiURI,
    IS_DEV: _isDev,
    SOCKET: socketURI
};
