import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { PasswordErrorStateMatcher } from 'src/app/shared/classes/password-error-state-matcher';
import { AppUtils } from 'src/app/shared/classes/utils';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.css']
})
export class CreateAccountComponent implements OnInit {

  matcher = new PasswordErrorStateMatcher();
  fieldTextType: boolean;
  repeatFieldTextType: boolean;
  partnerForm = this._formBuilder.group({
    userId: [''],
    name: [null, Validators.required],
    surname: [null, Validators.required],
    email: [null, [
      Validators.email,
      Validators.required]
    ]
  });

  contactForm = this._formBuilder.group({
    contact: [null, [
      Validators.required,
      Validators.maxLength(10),
      Validators.pattern('[0-9]{10}'),
      Validators.minLength(10)]
    ]
  });

  passwordForm = this._formBuilder.group({
    password: [''],
    passwordConfirm: ['']
  }, { validator: AppUtils.checkPasswords });

  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  isLinear = false;

  constructor(private _formBuilder: FormBuilder, public preLoader: LoadingService) { }

  ngOnInit(): void {
  }

  toggleShowPassword() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleRepeatFieldTextType() {
    this.repeatFieldTextType = !this.repeatFieldTextType;
  }

  get name() {
    return this.partnerForm.get('name');
  }

  get surname() {
    return this.partnerForm.get('surname');
  }

  get email() {
    return this.partnerForm.get('email');
  }

  get contact() {
    return this.contactForm.get('contact');
  }

  get password() {
    return this.passwordForm.get('password');
  }

  get passwordConfirm() {
    return this.passwordForm.get('passwordConfirm');
  }

}
