import { PartnerService } from 'src/app/admin/pages/partner/partner.service';
import { Partner } from 'src/app/admin/pages/partner/partner';
import { OrderService } from 'src/app/admin/pages/order/order.service';
import { Mail } from '../classes/mail';
import { Injectable } from '@angular/core';
import { ENV } from 'src/app/core/env.config';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { LogService } from './log.service';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { Order } from 'src/app/admin/pages/order/order';
import { AppUtils } from '../classes/utils';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  private baseUrl: string = `${ENV.BASE_API}`;
  private accessPointUrl: string = this.baseUrl + 'mail';
  mail: Mail;
  isSubmitted = false;

  constructor(
    private http: HttpClient,
    private error: ErrorService,
    private orderService: OrderService,
    private partner: PartnerService
  ) { }

  /** POST: Send email message */
  sendMail$(mail: Mail): Observable<Mail> {
    return this.http.post<Mail>(this.accessPointUrl, mail).pipe(
      catchError(this.error.handleError<Mail>('sendMail'))
    );
  }

  sendEmailOrderUpdate(order: Order) {
    // Get order number
    const orderNumber = this.orderService.getOrderNumber(order._id, AppUtils.setDate(order.orderDate));

    // Get customer order update mail object
    let mail = Mail.getOrderUpdateNotification(order, orderNumber);

    // Send mail
    this.sendMail$(mail).subscribe();
  }

  sendEmailOwnerNotification(order: Order) {
    // Get order number
    const orderNumber = this.orderService.getOrderNumber(order._id, AppUtils.setDate(order.orderDate));


    order.partnerOrder.forEach(detail => {
      this.partner.getPartner$(detail.partner).subscribe(partner => {
        if(partner){
        //Get copy of order
        let partnerOrder = JSON.parse(JSON.stringify(order));

        // Filter for partner orders
        partnerOrder.orderDetail = partnerOrder.orderDetail.filter(d => d.partner === detail.partner);
        
        // Get product owner mail object
        let mail = Mail.getProductOwnerNotifcation(partnerOrder, orderNumber, partner.email);

        console.log(JSON.stringify(mail));

        // Send mail
        //this.sendMail$(mail).subscribe();
        }

      })
    })
  }

  sendCustomerNotification(order: Order) {
    // Get order number
    const orderNumber = this.orderService.getOrderNumber(order._id, AppUtils.setDate(order.orderDate));

    // Get customer mail object
    let mail = Mail.getCustomerNotifcation(order, orderNumber);

    // Send mail
    this.sendMail$(mail).subscribe();
  }

  sendSignupNotification(partner: Partner): Observable<any> {
    // Get customer mail object
    let mail = Mail.getCompleteSignupNotification(partner);

    // Send mail
    return this.sendMail$(mail);
  }
}
