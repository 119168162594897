import { VerificationService } from './../verification.service';
import { Component, Inject, Input, OnInit, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Customer } from 'src/app/admin/pages/customer/customer';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { Location } from '@angular/common';
import { Verification } from '../verification';
import { concat } from 'rxjs';
import { concatAll } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { MatGridTileHeaderCssMatStyler } from '@angular/material/grid-list';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import { LOCAL_STORAGE_PARTNER } from 'src/app/pages/partner-reg/partner-reg.component';


@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css']
})
export class VerificationComponent implements OnInit, OnDestroy {

  pageTitle = 'Verification';
  loading: boolean;
  @Input() toPhoneNumber = '';
  numberValidations = [
    Validators.required,
    Validators.maxLength(1),
    Validators.pattern('[0-9]'),
    Validators.minLength(1)];

  codeForm = this.fb.group({
    Code1: [null, this.numberValidations],
    Code2: [null, this.numberValidations],
    Code3: [null, this.numberValidations],
    Code4: [null, this.numberValidations],
    Code5: [null, this.numberValidations],
    Code6: [null, this.numberValidations]
  });
  isVerified: boolean;
  numberMask: string;
  hasError: boolean;
  
  

  constructor(
    private fb: FormBuilder,
    private title: Title,
    private pgLocation: Location,
    private verificationService: VerificationService,
    public preLoader: LoadingService,
    private elRef:ElementRef,
    private router: Router
  ) { }

  ngOnInit(): void {
    // Set browser tab title
    this.title.setTitle(this.pageTitle);

    // Send verification code to user
    //this.sendVerificationCode();

    this.preLoader.loading = false;

    // Mask last 4 digits of 'to' number
    //this.numberMask = this.verificationService.verification.to.replace(/\d(?=\d{4})/g, "*");
    this.numberMask = localStorage.getItem(LOCAL_STORAGE_PARTNER.PHONE_NUMBER_KEY).replace(/\d(?=\d{4})/g, "*");

    // Initiate verification if launched through redirect 
    // with some local storage variables assigned for contact number
    if(this._hasLocalStorageVariables()){
      this.verificationService.sendVerificationCode(localStorage.getItem(LOCAL_STORAGE_PARTNER.PHONE_NUMBER_KEY));
      this.verificationService.setTargetRoute(localStorage.getItem(LOCAL_STORAGE_PARTNER.TARGET_ROUTE_KEY));
      this.verificationService.setSuccessRoute(localStorage.getItem(LOCAL_STORAGE_PARTNER.SUCCESS_ROUTE_KEY))
    }
  }

  checkVerificationCode() {
    // Set loading flag
    this.loading = true;

    // Set code
    this.verificationService.verification.code = this._getConcatenatedCode();

    // Check verification status and navigate to target route
    this.verificationService.checkVerification$(this.verificationService.verification).subscribe(status => {
      if(status == "approved") {
        // Set verification flag
        this.isVerified = true;
        this.verificationService.isVerified$.next(true);

        // Reset loading flag
        this.loading = false;
        
        // Navigate to success route from trigger component
        this.router.navigate([this.verificationService.successRoute]); 
      } else {
        // Set verification flag
        this.isVerified = false;
        this.verificationService.isVerified$.next(false);

        // Reset loading flag
        this.loading = false;
      }
    }, 
    error => {
      this.hasError = true;
    })


  }

  resendVerificationCode(){
    this.verificationService.sendVerificationCode(this.verificationService.verification.to);
  }

  tabChange(val) {
    let ele = this.elRef.nativeElement.querySelectorAll('input');
    if (ele[val - 1].value != '') {
      ele[val].focus()
    } else if (ele[val - 1].value == '') {
      ele[val - 2].focus()
    }
  }

  goBack() {
    this.pgLocation.back();
  }

  _getConcatenatedCode() {
    return this.Code1.value.concat(this.Code2.value, this.Code3.value, this.Code4.value, this.Code5.value, this.Code6.value);
  }

  _hasLocalStorageVariables(){
    return localStorage.getItem(LOCAL_STORAGE_PARTNER.PHONE_NUMBER_KEY) !== null && 
     localStorage.getItem(LOCAL_STORAGE_PARTNER.TARGET_ROUTE_KEY) !== null
  }

  _clearLocalStorageVariables(){
    localStorage.removeItem(LOCAL_STORAGE_PARTNER.PHONE_NUMBER_KEY);
    localStorage.removeItem(LOCAL_STORAGE_PARTNER.TARGET_ROUTE_KEY);
    localStorage.removeItem(LOCAL_STORAGE_PARTNER.SUCCESS_ROUTE_KEY);
  }

  ngOnDestroy() {
    this._clearLocalStorageVariables();
  }

  // Getters

  get Code1() {
    return this.codeForm.get('Code1');
  }

  get Code2() {
    return this.codeForm.get('Code2');
  }

  get Code3() {
    return this.codeForm.get('Code3');
  }

  get Code4() {
    return this.codeForm.get('Code4');
  }

  get Code5() {
    return this.codeForm.get('Code5');
  }

  get Code6() {
    return this.codeForm.get('Code6');
  }

}
