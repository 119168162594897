import { Component, Input, OnInit } from '@angular/core';
import { CartItem } from 'src/app/pages/cart/cart-item';
import { CartService } from 'src/app/pages/cart/cart.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { Product } from '../product';
import { ProductService } from '../product.service';

@Component({
  selector: 'app-product-catalogue',
  templateUrl: './product-catalogue.component.html',
  styleUrls: ['./product-catalogue.component.css']
})
export class ProductCatalogueComponent implements OnInit {

  products: Product[];
  @Input() partnerId: string;
  quantity = 1;
  cartItems: CartItem[];

  constructor(
    public productService: ProductService,
    public cart: CartService,
    private messsage: MessageService
  ) { }

  ngOnInit(): void {
    //this.productService.getProducts$().subscribe(products => this.products = products);
    this._getProductsByPartner(this.partnerId);
  }

  private _getProductsByPartner(partnerId) {
    this.productService
      .getProductsByPartner$(partnerId)
      .subscribe(products => {
        this.products = products;
      });
  }

  addToCart(product: Product) {
    this.cart.add(product);
    this.messsage.add(product.name + " addded to cart");
  }

  getQuantity(product: Product) {
    return this.cart.count(product._id);
  }
}
