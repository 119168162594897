import { PushNotificationsService } from './shared/services/push-notifications.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'Emabhansini';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notifications: PushNotificationsService
  ) { }

  ngOnInit() {
    // Scoll to top of page after every route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    // Listening out for push notifications 
    // and redirect based on redirect info
    this.notifications.notificationClickRedirect();
  }

  isAdminRoute(){
    return (this.router.url.indexOf('/admin') > -1)
  }
  
}
