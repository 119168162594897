import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  pageTitle = 'Emabhansini | Terms of Service';

  constructor(private title: Title) { }

  ngOnInit(): void {
    // Set browser tab title
    this.title.setTitle(this.pageTitle);
  }

}
