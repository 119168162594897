import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  pageTitle = 'Emabhansini | Privacy Policy';

  constructor(private title: Title) { }

  ngOnInit(): void {
    // Set browser tab title
    this.title.setTitle(this.pageTitle);
  }

}
