import { Injectable } from '@angular/core';
import { Toaster, ToastType } from 'ngx-toast-notifications';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private toaster: Toaster) { }
  
  add(message: string, header: string = 'Notification') {
    this._showToast(message, header);
  }

  private _showToast(message: string,
    caption: string,
    classType: ToastType = 'dark') {
    this.toaster.open({
      text: message,
      caption: caption,
      duration: 4000,
      type: classType
    });
  }
}
