
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VerificationRoutingModule } from './verification-routing.module';
import { VerificationSuccessComponent } from './verification-success/verification-success.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VerificationComponent } from './verification/verification.component';


@NgModule({
  declarations: [
    VerificationComponent,
    VerificationSuccessComponent
  ],
  imports: [
    SharedModule,
    VerificationRoutingModule
  ],
  exports: [
    VerificationComponent
  ]
})
export class VerificationModule { }
