<div *ngFor="let product of products" class="d-flex flex-row row-hover justify-content-between mb-3 py-3">
  <div class="col-md-2 align-self-center d-none d-lg-block">
    <!-- <div class="img" [ngStyle]="{'background-image': 'url(' + product.imageUrl + ')'}"></div> -->
    <ngx-picture src="{{ product.imageUrl }}" alt="product image">
    </ngx-picture>
  </div>

  <div class="col-md-4 align-self-center" [routerLink]="['./', product._id]">
    <h5>{{product.name}}</h5>
    <p>{{product.description}}</p>
  </div>

  <div class="col-md-2 align-self-center text-center">
    <span>{{product.price | currency:'R'}}</span>
  </div>

  <div class="col-md-2 d-block align-self-center text-right">
    <ng-container *ngIf="getQuantity(product) === 0;else quantityContainer">
      <button (click)="addToCart(product)" class="btn btn-primary">Add</button>
    </ng-container>
    <ng-template #quantityContainer>
      <div class="col-md-2 d-flex">
        <span class="material-symbols-outlined" (click)="cart.reduceQuantity(product)">
          do_not_disturb_on
        </span>
        <p class="mx-3 my-0">{{ getQuantity(product) }}</p>
        <span class="material-symbols-outlined" (click)="addToCart(product)">
          add_circle
        </span>
      </div>
    </ng-template>

  </div>
</div>