import { ROLE } from 'src/app/core/role.config';
import { CustomerService } from './../../admin/pages/customer/customer.service';
import { Injectable } from '@angular/core';
import { ENV } from 'src/app/core/env.config';
import { USER_CONFIG } from 'src/app/core/user.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LogService } from 'src/app/shared/services/log.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { Observable, of, Subject, BehaviorSubject, throwError } from 'rxjs';
import { tap, catchError, map, concatMap } from 'rxjs/operators';
import { Profile } from './profile';
import { ProfileRole } from './profile-role';
import { AuthProperties } from 'src/app/auth/auth-properties';
import { AUTH_CONFIG } from 'src/app/auth/auth.config';
import { AuthService, User } from '@auth0/auth0-angular';



@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private baseUrl: string = `${ENV.BASE_API}`;
  private accessPointUrl: string = `${ENV.USER_MGMT_API}users/`;
  managementAccessToken: string;
  private _authMgmt = {
    client_id: USER_CONFIG.CLIENT_ID,
    client_secret: USER_CONFIG.CLIENT_SECRET,
    audience: USER_CONFIG.AUDIENCE,
    grant_type: USER_CONFIG.GRANT_TYPE
  }
  role: ProfileRole;
  userRole: string;
  userRole$: Subject<string> = new Subject();
  isAdmin$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  userId: string;
  user: User;
  location = { lat: -26.255716, long: 28.167439 }; // TODO: Get from admin profile

  constructor(
    private http: HttpClient,
    private error: ErrorService,
    private _auth: AuthService
  ) {
    this._setManagementAccessToken$().subscribe();
    this._initUser();
  }

  /** GET: Get profile by userId*/
  getProfile$(userId: string): Observable<any> {
    return this.http.get<any>(this.accessPointUrl + userId, {
      headers: new HttpHeaders().set('Authorization', this._authHeader)
    })
      .pipe(
        tap(),
        catchError(this.error.handleError<any>('getUser'))
      );
  }

  /** PATCH: Update profile meta data*/
  updateProfile$(user_id: string, profile: any): Observable<any> {
    return this.http.patch(this.accessPointUrl + user_id, profile, {
      headers: new HttpHeaders().set('Authorization', this._authHeader)
    })
      .pipe(
        catchError(this.error.handleError<any>('updateProfile'))
      );
  }

  /** POST: add a new profile to the server */
  addProfile$(profile: Profile): Observable<Profile> {
    return this.http.post<Profile>(`${ENV.USER_MGMT_API}users`, profile, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this._authHeader
      })
    })
      .pipe(
        catchError(this.error.handleError<Profile>('addUser'))
      );
  }

  assignUserRole(user: User, roleId: string) {
    // Use HttpClient to make the call
    this.http.post(encodeURI(`https://dev-egua4bns.eu.auth0.com/api/v2/roles/${roleId}/users`),
      { "users": [user.sub] },
      { headers: new HttpHeaders().set('Authorization', this._authHeader) }
    ).subscribe()
  }

  private _setManagementAccessToken$(): Observable<any> {
    return this.http.post<any>(USER_CONFIG.ACCESS_POINT, this._authMgmt)
      .pipe(
        tap(response => {
          this.managementAccessToken = response.access_token;
        }),
        catchError(this.error.handleError<any>('getManagementAccesstoken'))
      );
  }

  private _initUser() {
    this._auth.user$.subscribe(user => {
      // Set global variables for currently logged in user
      this._setUserRole(user);
      this.user = user;

    }, err => console.log('User Error', err))
  }

  private _setUserRole(user) {
    if (user) {
      // Get user role and store in an observable
      this.userRole$.next(user[AUTH_CONFIG.ROLE_SCOPE2][0]);

      // Set whether or not the user is admin or not
      if (user[AUTH_CONFIG.ROLE_SCOPE2][0] === ROLE.ADMIN) { this.isAdmin$.next(true); }
    }
  }

  private get _authHeader(): string {
    return `Bearer ${this.managementAccessToken}`;
  }
}
