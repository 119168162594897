import { AuthProperties } from 'src/app/auth/auth-properties';
import { MessageService } from './../shared/services/message.service';
import { CustomerService } from './../admin/pages/customer/customer.service';
import { ProfileService } from '../pages/profile/profile.service';
import { AUTH_CONFIG } from './auth.config';
import { Injectable, ɵConsole } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { tap, catchError, concatMap, shareReplay, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { USER_CONFIG } from '../core/user.config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // Auth management config
  private _authMgmt = {
    client_id: USER_CONFIG.CLIENT_ID,
    client_secret: USER_CONFIG.CLIENT_SECRET,
    audience: USER_CONFIG.AUDIENCE,
    grant_type: USER_CONFIG.GRANT_TYPE
  }

  // Create an observable of Auth0 instance of client
  auth0Client$ = (from(
    createAuth0Client({
      domain: AUTH_CONFIG.CLIENT_DOMAIN,
      client_id: AUTH_CONFIG.CLIENT_ID,
      redirect_uri: `${window.location.origin}`,
      audience: AUTH_CONFIG.AUDIENCE
    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError(err => throwError(err))
  );
  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => this.loggedIn = res)
  );
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  loggedIn: boolean = null;
  // Local property for email verfied status
  isEmailVerified: boolean = null;

  constructor(private router: Router,
   
    private profile: ProfileService,
   
    private customer: CustomerService,
    private message: MessageService,
    private http: HttpClient

  ) {
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.localAuthSetup();
    // Handle redirect from Auth0 login
    this.handleAuthCallback();
    // Get access token
    this.getTokenSilently$().subscribe(token => {
        AuthProperties.accessToken$.next(token);
      }
    );
    //Set management access token for user creation
    this._setManagementAccessToken$().subscribe();
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => {
        this.userProfileSubject$.next(user);

        // Set user role
        // this.profile.initRole(user.sub);       
        this.profile.userRole = user[AUTH_CONFIG.ROLE_SCOPE][0];

        // Set userId
        this.profile.userId = user.sub;

        // Set customerId
        this._initCustomerId(user.sub);

        // Request user to update profile
        this._checkUserProfileCompletion(user);

        // Set email verified status. Alert user to verify if not verified
        this.isEmailVerified = user.email_verified;
        if (!user.email_verified) {
          this.message.add("Please verify your email by clicking link sent to " + user.name);
        }
      })
    );
  }

  private localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          // return this.getUser$();
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe();
  }

  login(redirectPath: string = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}`,
        appState: { target: redirectPath }
      });
    });
  }

  signup(redirectPath: string, email: string) {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: redirectPath,
        appState: { target: redirectPath },
        action: 'signup', 
        login_hint: email
      });
    });
  }

  private handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
    const params = window.location.search;
    if (params.includes('code=') && params.includes('state=')) {
      let targetRoute: string; // Path to redirect to after login processsed
      const authComplete$ = this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        tap(cbRes => {
          // Get and set target redirect route from callback results
          targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
        }),
        concatMap(() => {
          // Redirect callback complete; get user and login status
          return combineLatest([
            this.getUser$(),
            this.isAuthenticated$
          ]);
        })
      );
      // Subscribe to authentication completion observable
      // Response will be an array of user and login status
      authComplete$.subscribe(([user, loggedIn]) => {
        // Redirect to target route after callback processing
        this.router.navigate([targetRoute]);
      });
    }
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: AUTH_CONFIG.CLIENT_ID,
        returnTo: AUTH_CONFIG.RETURN_TO
      });
    });
  }

  getTokenSilently$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
    );
  }

  // Set management access token for creating profile
  private _setManagementAccessToken$(): Observable<any> {
    return this.http.post<any>(USER_CONFIG.ACCESS_POINT, this._authMgmt)
      .pipe(
        tap(response => {
          AuthProperties.mgmtAccessToken = response.access_token; 
        }),
        catchError(err => throwError(err))
      );
  }

  private _initCustomerId(userId: string) {
        this.customer.setCustomer(userId);
  }

  private _checkUserProfileCompletion(profile: any){
    if(!profile[AUTH_CONFIG.PROFILE_SCOPE].family_name){
      this.message.add("Welcome, " + profile.nickname + "! Please complete your signup by updating profile");
    }
  }

}
