export class Partner {
    name: string;
    email: string;
    surname: string;
    contact: string;
    businessAddress: string;
    businessName: string;
    pushSubscription?: any;
    latitude: number;
    longitude: number;
    isApproved: boolean = false;
    registrationComplete: boolean = false;
    merchantId: number;
    dateCreated: Date;
    dateModified: Date;
    currentServices?: any;
    canDeliver?: boolean = false;
    deliveryType?: string;
    deliveryRadius?: number;
    costPerKM?: number;
    fixedDeliveryAmount?: number;
    addressDetail?: string;
    signupReason?: string;
    idImageUrl?: string;
    selfieImageUrl?: string;
    accountHolder?: string;
    bankName?: string;
    accountNumber?: number;
    foodType?: string[];
    userId?: string;
    _id?: string;
  
    constructor(partnerForm?: any, location?: string, latitude?: number, longitude?: number) {
      if (partnerForm) {
        this.name = partnerForm.name;
        this.surname = partnerForm.surname;
        this.email = partnerForm.email;
        this.contact = partnerForm.contact;
        this.businessName = partnerForm.businessName;
        this.businessAddress = location;
        this.latitude = latitude;
        this.longitude = longitude;
        this.canDeliver = partnerForm.canDeliver;
        this.businessAddress = location;
        this.addressDetail = partnerForm.addressDetail;
        this.foodType = partnerForm.foodType;
      }
    }
  
  }
  