import { ENV } from '../core/env.config';

interface AuthConfig {
    CLIENT_ID: string;
    CLIENT_DOMAIN: string;
    AUDIENCE: string;
    REDIRECT: string;
    RETURN_TO: string;
    PROFILE_SCOPE: string;
    ROLE_SCOPE: string; 
    ROLE_SCOPE2: string; 
};

export const AUTH_CONFIG: AuthConfig = {
    CLIENT_ID: 'From71QoE4nrwws1yQA6UINVTm9jOrVz',
    CLIENT_DOMAIN: 'dev-egua4bns.eu.auth0.com',
    AUDIENCE: 'https://api.emabhansini.co.za',
    REDIRECT: `${ENV.BASE_URI}/callback`,
    RETURN_TO: `${ENV.BASE_URI}`,
    PROFILE_SCOPE: 'https://emabhansini.co.za/profile',
    ROLE_SCOPE: 'https://emabhansini.co.za/roles',
    ROLE_SCOPE2: 'https://emabhansini.co.za/roles2'
};