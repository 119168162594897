import { HttpErrorResponse } from "@angular/common/http";
import { FormGroup } from "@angular/forms";
import { Observable, of } from "rxjs";

export class AppUtils {
  static getCurrentDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return `${dd}${mm}${yyyy}`;
  }

  static setDate(date: Date) {
    var today = new Date(date);

    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return `${dd}${mm}${yyyy}`;
  }

  static checkPasswords(group: FormGroup) {
    let pass = group.get('password').value;
    let confirmPass = group.get('passwordConfirm').value;

    return pass === confirmPass ? null : { notSame: true }
  }


  static handleError(err: HttpErrorResponse | any): Observable<any> {
    const errorMsg = err.message || 'Error: Unable to complete request.';
    return of(errorMsg);
  }
}
