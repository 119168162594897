import { AUTH_CONFIG } from 'src/app/auth/auth.config';
import { PaymentModule } from './shared/modules/payment/payment.module';
import { SharedModule } from './shared/modules/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AgmCoreModule } from '@agm/core';
import { CallbackComponent } from './pages/callback/callback.component';
import { LoadingInterceptor } from './components/loading/loading.interceptor';
import { CLOUDINARY_CONFIG, NgxPictureModule } from 'ngx-picture';
import { DatePipe } from '@angular/common';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastNotificationsModule } from 'ngx-toast-notifications';
import { CloudinaryModule } from '@cloudinary/angular-5.x';
import * as  Cloudinary from 'cloudinary-core';
import {FileUploadModule} from 'ng2-file-upload';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { PartnerNewComponent } from './admin/pages/partner/partner-new/partner-new.component';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { PartnerRegComponent } from './pages/partner-reg/partner-reg.component';
import { PartnerRegistrationSuccessComponent } from './pages/partner-registration-success/partner-registration-success.component';
import { ENV } from './core/env.config';
import { AccountModule } from './shared/modules/account/account.module';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    CallbackComponent,
    TermsComponent,
    PrivacyComponent,
    PartnerNewComponent,
    PartnerRegComponent,
    PartnerRegistrationSuccessComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastNotificationsModule,
    PaymentModule,
    AccountModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDW1QpC1q1RbMfrlFKT4bFWL0NYCblBxhY',
      libraries: ['places', 'geometry']
    }),
    TooltipModule.forRoot(),
    NgxPictureModule.forRoot(CLOUDINARY_CONFIG),
    FileUploadModule,
    CloudinaryModule.forRoot(Cloudinary, { cloud_name: 'db2qfhly1', upload_preset: 'vuxh2pbp'}),
    AuthModule.forRoot({
      domain: AUTH_CONFIG.CLIENT_DOMAIN,
      clientId: AUTH_CONFIG.CLIENT_ID,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: AUTH_CONFIG.AUDIENCE
      },
      httpInterceptor: {
        allowedList: [
          `${ENV.BASE_API}alerts*`,
          `${ENV.BASE_API}customers*`,
          `${ENV.BASE_API}orders*`,
          `${ENV.BASE_API}partners/*`
        ]
      },
    }),
    SharedModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(update: SwUpdate) {

    // App version update notification
    update.available.subscribe(update => {
      if (confirm("A new version of the app is available and will be automatically updated.")) {
        window.location.reload();
      }
    })
  }
}
