import { Location } from './../../../shared/classes/location';
import { Injectable } from '@angular/core';
import { ENV } from 'src/app/core/env.config';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthProperties } from 'src/app/auth/auth-properties';

@Injectable({
  providedIn: 'root'
})
export class PickUpPointService {

  private baseUrl: string = `${ENV.BASE_API}`;
  private accessPointUrl: string = this.baseUrl + 'pickupPoints';
  newRecords = [];
  pickupPoint: Location;

  constructor(
    private http: HttpClient
  ) { }

  /** GET: Get List of PickupPoints*/
  getPickupPoints$(): Observable<Location[]> {
    return this.http.get<Location[]>(this.accessPointUrl).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** GET: PickupPoint By Id */
  getPickupPoint$(id: string): Observable<Location> {
    const url = `${this.accessPointUrl}/${id}`;
    return this.http.get<Location>(url).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** PUT: Update PickupPoint*/
  updatePickupPoint$(pickupPoint: Location): Observable<any> {
    return this.http.put(this.accessPointUrl + "/" + pickupPoint._id, pickupPoint, {
      headers: new HttpHeaders().set('Authorization', this._authHeader)
    }).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** POST: Create new PickupPoint */
  addPickupPoint$(pickupPoint: Location): Observable<Location> {
    return this.http.post<Location>(this.accessPointUrl, pickupPoint, {
      headers: new HttpHeaders().set('Authorization', this._authHeader)
    }).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** DELETE: Delete PickupPoint */
  deletePickupPoint$(id: string): Observable<any> {
    return this.http.delete(this.accessPointUrl + "/" + id, {
      headers: new HttpHeaders().set('Authorization', this._authHeader)
    }).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  private get _authHeader(): string {
    return `Bearer ${AuthProperties.accessToken$.value}`;
  }

  private _handleError(err: HttpErrorResponse | any): Observable<any> {
    const errorMsg = err.message || 'Error: Unable to complete request.';
    return of(errorMsg);
  }
}
