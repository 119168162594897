import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ENV } from 'src/app/core/env.config';
import { Verification } from './verification';

const VERIFICATION_CHANNELS = {
  SMS: "sms",
  CALL: "call",
  WHATSAPP: "whatsapp"
}

@Injectable({
  providedIn: 'root'
})
export class VerificationService {

  private baseUrl: string = `${ENV.BASE_API}`;
  private accessPointUrl: string = this.baseUrl + 'verification';
  private _targetRoute: string; // End route after verification
  private _successRoute: string; // Route to success page after verification
  verification: Verification;
  isVerified$: Subject<boolean> = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  // POST: Send Verification Code to User
  sendVerification$(verification: Verification): Observable<string> {
    return this.http.post<String>(this.accessPointUrl, verification).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  // POST: Verify User Verification Code
  checkVerification$(verification: Verification): Observable<string> {
    return this.http.post(this.accessPointUrl + "/check", verification, {responseType: 'text'}).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  sendVerificationCode(phoneNumber) {
    // Create verification object
    this.verification = new Verification();
    this.verification.to = phoneNumber;
    this.verification.channel = VERIFICATION_CHANNELS.SMS; //TODO: Update to toggle

    // Send verification code to user and navigate to code input screen
    this.sendVerification$(this.verification).subscribe(_ => this.router.navigate(['/verification']));
  }

  setTargetRoute (route: string) {
    this._targetRoute = route;
  };

  get targetRoute () {
    return this._targetRoute
  };

  setSuccessRoute (route: string) {
    this._successRoute = route;
  };

  get successRoute () {
    return this._successRoute
  };

  // Error Handler
  private _handleError(err: HttpErrorResponse | any): Observable<any> {
    const errorMsg = err.message || 'Error: Unable to complete request.';
    return of(errorMsg);
  }
}
