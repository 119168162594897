import { take } from 'rxjs/operators';
import { MailService } from 'src/app/shared/services/mail.service';
import { PartnerService } from 'src/app/admin/pages/partner/partner.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { VerificationService } from 'src/app/shared/modules/verification/verification.service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE_PARTNER } from '../partner-reg/partner-reg.component';
import { Partner } from 'src/app/admin/pages/partner/partner';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-partner-registration-success',
  templateUrl: './partner-registration-success.component.html',
  styleUrls: ['./partner-registration-success.component.css']
})
export class PartnerRegistrationSuccessComponent implements OnInit, OnDestroy {

  pageTitle = 'Emabhansini Partner Regisration Successful';

  constructor(
    private title: Title
  ) { }


  ngOnDestroy(): void {
    this._clearLocalStorage();
  }

  ngOnInit(): void {
    this.title.setTitle(this.pageTitle);

    // Show thank you message for 6 seconds
    // TODO: Delete once email signup flow has been tested and works
    // setTimeout(() => {
    //   this.router.navigate([this.verification.targetRoute || '/home']);
    // }, 6000);

    // Create new partner record from local storage data and send complete signup email
    // this._createNewPartner(JSON.parse(localStorage.getItem(LOCAL_STORAGE_PARTNER.PARTNER_KEY)));
  }

  private _clearLocalStorage() {
    localStorage.removeItem(LOCAL_STORAGE_PARTNER.PARTNER_KEY);
  }

}
