<!-- Footer Section Begin -->
<footer class="footer set-bg">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="footer__widget">
          <h6>WORKING HOURS</h6>
          <ul>
            <li>Monday - Friday: 8:00 am – 6:30 pm</li>
            <li>Saturday: 10:00 am – 4:30 pm</li>
            <li>Sunday: 10:00 am – 4:30 pm</li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="footer__about">
          <div class="footer__logo">
            <a href="#"><img
                src="https://res.cloudinary.com/db2qfhly1/image/upload/v1706358439/emabhansini/img/logo/logo-primary-light_fx3jn3.png"
                width="150" alt="Emabhansini logo mark"></a>
          </div>
          <p>Emabhansini is a dynamic online platform connecting independent bakers with customers 
            seeking delicious homemade treats, offering a diverse array of baked goods crafted 
            with love and delivered straight to your doorstep.</p>
          <div class="footer__social">
            <a href="https://www.facebook.com/emabhansini"><i class="icon-facebook"></i></a>
            <!-- <a href="#"><i class="fa fa-twitter"></i></a> -->
            <a href="https://www.instagram.com/emabhansini"><i class="icon-instagram"></i></a>
            <!-- <a href="#"><i class="fa fa-youtube-play"></i></a> -->
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="footer__newslatter">
          <h6>Subscribe</h6>
          <p class="mb-0">Get latest updates and offers.</p>
          <form action="#">
                      <input type="text" placeholder="Email">
                      <button type="submit"><i class="fa fa-send-o"></i></button>
                  </form>
          <iframe src="https://cdn.forms-content-1.sg-form.com/9f7718fe-981f-11ee-8d6d-366f1bd3e890" height="300"
            width="350" style="border:0;" aria-hidden="false" tabindex="0"></iframe>
        </div>
      </div> -->
    </div>
  </div>
  <div class="copyright">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <p class="copyright__text text-white">
            Copyright &copy;
            <script>document.write(new Date().getFullYear());</script> Emabhansini | All rights reserved
          </p>
        </div>
        <div class="col-lg-5">
          <div class="copyright__widget">
            <ul>
              <li><a href="/privacy" target=”_blank”>Privacy Policy</a></li>
              <li><a href="/terms" target=”_blank”>Terms & Conditions</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- Footer Section End -->