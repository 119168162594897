<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
  <a class="navbar-brand site-logo-font" href="#">
    <img src="/assets/img/logo-secondary.png" width="150" alt="" loading="lazy">
    <!-- <ngx-picture
      src="/assets/img/logo-primary.png"
      alt="emabhansini logo">
    </ngx-picture> -->
  </a>

  <span class="navbar-toggler hamburger hamburger--spring" [ngClass]="{'is-active': !isCollapsed}"
    (click)="isCollapsed =! isCollapsed" type="button" data-toggle="offcanvas">
    <span class="hamburger-box">
      <span class="hamburger-inner"></span>
    </span>
  </span>

  <div class="navbar-collapse offcanvas-collapse" id="appOffCanvasNav">
    <!-- <ul class="navbar-nav justify-content-center">
      <li><a class="nav-link" routerLink="/home" routerLinkActive="active" (click)="isCollapsed =! isCollapsed"
          data-toggle="offcanvas">Home</a></li>
    </ul> -->
    <span class="ml-auto">

      <ng-container *ngIf="auth.isAuthenticated$ | async; else loggedOut">
      <div class="dropdown d-inline">
        <a *ngIf="auth.user$ | async as user" class="d-inline pl-0 nav-link dropdown-toggle"
          data-toggle="dropdown" href="javascript:void(0)" role="button" aria-haspopup="true" aria-expanded="false">
          <span class="icon-user"></span>
          Welcome, {{ user.nickname }}
        </a>
        <div class="dropdown-menu">
          <a *ngIf="profile.isAdmin$ | async" class="dropdown-item" routerLink="/admin" (click)="closeSideBar()">Admin Panel</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:void(0)" (click)="auth.logout({logoutParams: { returnTo: getApplicationRoot() }})">Logout</a>
        </div>
      </div>
    </ng-container>

    <ng-template #loggedOut>
      <a href="javascript:void(0);" (click)="auth.loginWithRedirect({authorizationParams: {
        allowSignUp: false}})" class="d-inline nav-link"><span
          class="icon-user">
        </span> Sign in</a>
      </ng-template>

    </span>
  </div>

</nav>