import { CustomerService } from './../../admin/pages/customer/customer.service';
import { ProfileService } from './../../pages/profile/profile.service';
import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ENV } from 'src/app/core/env.config';
import { Observable, of, Subject } from 'rxjs';
import { Customer } from 'src/app/admin/pages/customer/customer';
import { catchError } from 'rxjs/operators';
import { PushNotification } from '../classes/push-notification';
import { AuthProperties } from 'src/app/auth/auth-properties';
import { PartnerService } from 'src/app/admin/pages/partner/partner.service';
import { Partner } from 'src/app/admin/pages/partner/partner';
export const USER_SUBCRIPTION_TYPE = {
  CUSTOMER: 'customer',
  PARTNER: 'partner'
}

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  isSubscribed: boolean;
  readonly VAPID_PUBLIC_KEY = "BHC6RV2akhRPa7AZkpTo-QxkR09Sy1RBtyaQJyeMZz23LwiOq99NzW988UU5FeOFB8rSx1LaGFz_eE1bQmtQdJQ";

  constructor(
    private swPush: SwPush,
    private customer: CustomerService,
    private http: HttpClient,
    private partner: PartnerService
  ) {
    
   }

  getUserPushSubscription(){
    this.swPush.subscription.subscribe(sub => {
      if(sub){
        this.isSubscribed = true;
      }else{
        this.isSubscribed = false;
      }
    })
  }

  subscribeToNotifications(subType: string, user: any) {
    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    })
      .then(sub => {
        // Determine if this is a customer or partner subscribing 
        // to notification and add subscription to correct table
        if(subType === USER_SUBCRIPTION_TYPE.CUSTOMER){
          this._addCustomerPushSubscriber(sub, user);
        } 

        if (subType === USER_SUBCRIPTION_TYPE.PARTNER){
          this._addPartnerPushSubscriber(sub, user);
        }
        
      })
      .catch(err => console.error("Could not subscribe to notifications", err));
  }

  unsubscribe(subType: string, user: any) {
    // Unsubscribes from Service Worker push notifications.
    this.swPush.unsubscribe();

    // Remove sub from customer profile depending on type of user
    if(subType === USER_SUBCRIPTION_TYPE.CUSTOMER){
      this._removeCustomerPushSubscriber(user);
    } 

    if (subType === USER_SUBCRIPTION_TYPE.PARTNER){
      this._removePartnerPushSubscriber(user);
    }
  }

  sendNotification(notification: PushNotification): Observable<any> {
    const url = `${ENV.BASE_API}notifications`;
    return this.http.post<any>(url, notification, {
      headers: new HttpHeaders().set('Authorization', this._authHeader)
    }).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  notificationClickRedirect(){
      this.swPush.notificationClicks.subscribe(payload =>
      {
        // console.log(
        //   'Action: ' + payload.action +
        //   'Notification data: ' + payload.notification.data +
        //   'Notification data.url: ' + payload.notification.data.url+
        //   'Notification data.body: ' + payload.notification.body
        // );
        window.open(`${payload.notification.data.url}shop`);
     });
  }

  private _handleError(err: HttpErrorResponse | any): Observable<any> {
    const errorMsg = err.message || 'Error: Unable to complete request.';
    return of(errorMsg);
  }

  private get _authHeader(): string {
    return `Bearer ${AuthProperties.accessToken$.value}`;
  }

  private _addCustomerPushSubscriber(sub: any, customer: Customer) {
    // Set push subscription object
    customer.pushSubscription = sub;

    // Update customer in database
    this.customer.updateCustomer$(customer).subscribe(_ => this.isSubscribed = true);
  }

  private _removeCustomerPushSubscriber(customer: Customer) {
    // Reset push subscription
    customer.pushSubscription = {};

    // Update customer in database
    this.customer.updateCustomer$(customer).subscribe(_ => this.isSubscribed = false);
  }

  private _addPartnerPushSubscriber(sub: any, partner: Partner) {
    // Set push subscription object
    partner.pushSubscription = sub;

    // Update customer in database
    this.partner.updatePartner$(partner).subscribe(_ => this.isSubscribed = true);
  }

  private _removePartnerPushSubscriber(partner: Partner) {
    // Reset push subscription
    partner.pushSubscription = {};

    // Update customer in database
    this.partner.updatePartner$(partner).subscribe(_ => this.isSubscribed = false);
  }
}
