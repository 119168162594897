<section class="site-section" *ngIf="!preLoader.loading">

  <div class="container-fluid">

    <div class="row mt-5">

      <!-- Partner Form -->
      <div class="col-md-8">
        <form [formGroup]="partnerForm" novalidate (ngSubmit)="createProfile()">
          <div class="card">
            <div class="card-header card-header-secondary">
              <p class="card-category">Complete your profile</p>
            </div>
            <div class="card-body">

              <div class="row">
                <div class="col">
                  <div class="form-group has-danger">
                    <label for="name">Email</label>
                    <input id="email" type="text" class="form-control"
                      [ngClass]="{'is-invalid': email.hasError('email')}" formControlName="email" required>
                    <div class="invalid-feedback" *ngIf="email.hasError('email')">
                      <strong>Invalid</strong> Email
                    </div>
                    <div class="invalid-feedback" *ngIf="email.hasError('required')">
                      <strong>Required</strong> Field
                    </div>
                  </div>
                </div>
              </div>

                <div class="row">

                  <div class="col-md-6">
                    <div class="form-group has-danger">
                      <label for="name">Name</label>
                      <input id="name" type="text" class="form-control"
                        [ngClass]="{'is-invalid': name.hasError('required')}" formControlName="name" required>
                      <div class="invalid-feedback" *ngIf="name.hasError('required')">
                        <strong>Required</strong> Field
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group has-danger">
                      <label for="surname">Surname</label>
                      <input id="surname" type="text" class="form-control"
                        [ngClass]="{'is-invalid': surname.hasError('required')}" formControlName="surname" required>
                      <div class="invalid-feedback" *ngIf="surname.hasError('required')">
                        <strong>Required</strong> Field
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="form-group has-danger">
                      <label for="contact">Contact</label>
                      <input id="contact" type="text" class="form-control" placeholder="Contact"
                        formControlName="contact" required minlength="10" maxlength="10">
                      <div class="invalid-feedback" *ngIf="contact.hasError('required')">
                        <strong>Required</strong> Field
                      </div>
                      <div class="invalid-feedback" *ngIf="contact.hasError('maxlength')">
                        <strong>Length Exceeded</strong>
                      </div>
                      <div class="invalid-feedback" *ngIf="contact.hasError('minlength')">
                        <strong>Invalid Length</strong>
                      </div>
                      <div class="invalid-feedback" *ngIf="contact.hasError('pattern')">
                        <strong>Invalid Field</strong>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="address">Address</label>
                      <input id="address" type="text" class="form-control" placeholder="Search for Location..."
                        [(ngModel)]="location.address" [ngModelOptions]="{standalone: true}" #address name="location">
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="businessName">What product(s) would you like to sell?</label>
                      <input id="businessName" type="text" class="form-control" placeholder=""
                        formControlName="businessName">
                    </div>
                  </div>
                </div>

                <mat-form-field class="example-chip-list" appearance="fill">
                  <mat-label>Favorite Fruits</mat-label>
                  <mat-chip-list #chipList aria-label="Fruit selection">
                    <mat-chip
                      *ngFor="let fruit of fruits"
                      (removed)="remove(fruit)">
                      {{fruit}}
                      <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip>
                    <input
                      placeholder="New fruit..."
                      #fruitInput
                      [formControl]="fruitCtrl"
                      [matAutocomplete]="auto"
                      [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      (matChipInputTokenEnd)="add($event)">
                  </mat-chip-list>
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">
                      {{fruit}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>

                <div class="row mt-2">
                    <div class="col-md-12">
                      
                     

                      <!-- <div class="form-group example-chip-list" appearance="fill">
                        <label for="businessName">Favorite Fruits</label>
                        <mat-chip-list #chipList aria-label="Fruit selection">
                            <mat-chip
                              *ngFor="let fruit of fruits"
                              [selectable]="selectable"
                              [removable]="removable"
                              (removed)="remove(fruit)">
                              {{fruit}}
                              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input class="form-control"
                              placeholder="New fruit..."
                              #fruitInput
                              [formControl]="fruitCtrl"
                              [matAutocomplete]="auto"
                              [matChipInputFor]="chipList"
                              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                              (matChipInputTokenEnd)="add($event)">
                          </mat-chip-list>
                          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                            <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">
                              {{fruit}}
                            </mat-option>
                          </mat-autocomplete>
                      </div> -->

                    </div>
                  </div>

                <!-- <mat-form-field class="example-chip-list" appearance="fill">
                    <mat-label>Favorite Fruits</mat-label>
                    <mat-chip-list #chipList aria-label="Fruit selection">
                      <mat-chip
                        *ngFor="let fruit of fruits"
                        [selectable]="selectable"
                        [removable]="removable"
                        (removed)="remove(fruit)">
                        {{fruit}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                      </mat-chip>
                      <input
                        placeholder="New fruit..."
                        #fruitInput
                        [formControl]="fruitCtrl"
                        [matAutocomplete]="auto"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="add($event)">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                      <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">
                        {{fruit}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field> -->
                  

                <div class="row align-items-end" formGroupName="passwordForm">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="password">Password</label>
        
                        <div class="input-group">
                          <input [type]="fieldTextType ? 'text' : 'password'" id="password" class="form-control"
                            formControlName="password" />
                          <div class="input-group-append">
                            <span class="input-group-text">
                              <i [ngClass]="{'icon-eye-slash': !fieldTextType,
                                'icon-eye': fieldTextType}" (click)="toggleShowPassword()">
                              </i>
                            </span>
                          </div>
                        </div>
        
                      </div>
                    </div>
        
                    <div class="col-md-6">
                      <div class="form-group has-danger">
                        <label for="passwordConfirm">Confirm Password</label>
        
                        <div class="input-group">
                          <input id="passwordConfirm" [type]="repeatFieldTextType ? 'text' : 'password'" class="form-control"
                            [ngClass]="{'is-invalid': passwordForm.hasError('notSame') && (passwordConfirm.dirty || passwordConfirm.touched)}"
                            formControlName="passwordConfirm" />
                          <div class="input-group-append">
                            <span class="input-group-text">
                              <i [ngClass]="{'icon-eye-slash': !repeatFieldTextType,
                                'icon-eye': repeatFieldTextType}" (click)="toggleRepeatFieldTextType()">
                              </i>
                            </span>
                          </div>
                        </div>
        
                        <div class="invalid-feedback"
                          *ngIf="passwordForm.hasError('notSame') && (passwordConfirm.dirty || passwordConfirm.touched)">
                          Password does not match
                        </div>
                      </div>
                    </div>
                  </div>

              <button class="btn btn-primary py-3 px-4" type="submit"
                [disabled]="!partnerForm.valid || !readTerms">Submit</button>
              <div class="custom-control mb-5">
                <input class="custom-control-input" type="checkbox" value="" id="flexCheckChecked" [checked]="readTerms"
                  (change)="readTerms = !readTerms">
                <label class="custom-control-label" for="flexCheckChecked">
                  By clicking Pay Now you agree to our <a href="/terms" target="_blank">Terms of Service</a> and <a
                    href="/refund" target="_blank">Refund Policy</a>.
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>