export class Profile {
  email: string;
  given_name: string;
  family_name: string;
  user_metadata = {
    family_name: '',
    given_name: '',
    contact: '',
    address: '',
    addressDetail: ''
  };
  email_verified: boolean;
  picture: string;
  connection: string;
  password: string;
  user_id?: string;

  constructor(form?: any, profile?: any, passwordForm?: any) {
    if (form) {
      this.email = form.email? form.email : null;
      this.user_metadata.family_name = form.surname? form.surname : null;
      this.user_metadata.given_name = form.name? form.name : null;
      this.user_metadata.contact = form.contact? form.contact : null;

      if (form.addressDetail) { this.user_metadata.addressDetail = form.addressDetail; }
    }

    if (passwordForm) {
      this.password = passwordForm.password;
    }

    if (profile) {
      this.user_metadata.family_name = profile.user_metadata['family_name'];
      this.user_metadata.given_name = profile.user_metadata['given_name'];
      this.user_metadata.contact = profile.user_metadata['contact'];
      this.user_metadata.addressDetail = profile.user_metadata['addressDetail'];
    }
  }
}
