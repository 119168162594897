import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, of, BehaviorSubject, ReplaySubject } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { AuthProperties } from 'src/app/auth/auth-properties';
import { ENV } from 'src/app/core/env.config';
import { Partner } from './partner';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  private baseUrl: string = `${ENV.BASE_API}`;
  private accessPointUrl: string = this.baseUrl + 'partners';
  newRecords = [];
  partner: Partner;
  partner$ = new ReplaySubject<Partner>(1);

  constructor(
    private http: HttpClient
  ) { }

  /** GET: Get List of Partners*/
  getPartners$(): Observable<Partner[]> {
    return this.http.get<Partner[]>(this.accessPointUrl).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** GET: Partner By Id */
  getPartner$(id: string): Observable<Partner> {
    const url = `${this.accessPointUrl}/${id}`;
    return this.http.get<Partner>(url).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** GET: Partner By Auth Id */
  getPartnerByEmail$(email: string): Observable<Partner> {
    const url = `${this.accessPointUrl}/email/${email}`;
    return this.http.get<Partner>(url).pipe(
      tap(partner => this.partner = partner),
      catchError((error) => this._handleError(error))
    );
  }

  /** PUT: Update entire Partner record */
  updatePartner$(partner: Partner): Observable<any> {
    return this.http.put(this.accessPointUrl + "/" + partner._id, partner).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** POST: Create new Partner and add changes to log */
  addPartner$(partner: Partner): Observable<Partner> {
    return this.http.post<Partner>(this.accessPointUrl, partner).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** DELETE: Delete Partner Record */
  deletePartner$(id: string): Observable<any> {
    return this.http.delete(this.accessPointUrl + "/" + id).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  private _handleError(err: HttpErrorResponse | any): Observable<any> {
    const errorMsg = err.message || 'Error: Unable to complete request.';
    console.log("Error: " + errorMsg);
    return of(errorMsg);
  }
}
