import { Injectable } from '@angular/core';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    private messages: MessageService
  ) { }

  //TODO: Write to backend log file
  add(message: string) {
    // this.messages.add(message);
  }
}
