import { AUTH_CONFIG } from '../auth/auth.config';

interface UserConfig {
    AUDIENCE: string;
    DOMAIN: string;
    CLIENT_ID: string;
    CLIENT_SECRET: string;
    GRANT_TYPE: string;
    ACCESS_POINT: string;
    USER_API_CONNECTION;
  };
  
  export const USER_CONFIG: UserConfig = {
    AUDIENCE: 'https://dev-egua4bns.eu.auth0.com/api/v2/',
    DOMAIN: AUTH_CONFIG.CLIENT_DOMAIN,
    CLIENT_ID: 'WgR5O10BXwCu9ib5NmQ8gQMeXbRy8zAc',
    CLIENT_SECRET: 'o0rHt-6oVfeA0bonc08blseQ7O8-1t2GcGp7qEBQ5_fX0LnU3AlSHWMJOdR0kGlP',
    GRANT_TYPE: 'client_credentials',
    ACCESS_POINT: 'https://dev-egua4bns.eu.auth0.com/oauth/token',
    USER_API_CONNECTION: 'Username-Password-Authentication'
  };