import { AuthService } from 'src/app/auth/auth.service';
import { Injectable } from '@angular/core';
import { ENV } from 'src/app/core/env.config';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from 'src/app/shared/services/error.service';
import { LogService } from 'src/app/shared/services/log.service';
import { Observable, of } from 'rxjs';
import { Product } from './product';
import { tap, catchError } from 'rxjs/operators';
import { httpOptions } from 'src/app/core/http.options';
import { AuthProperties } from 'src/app/auth/auth-properties';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private baseUrl: string = `${ENV.BASE_API}`;
  private accessPointUrl: string = this.baseUrl + 'products';
  newRecords = [];

  constructor(
    private http: HttpClient
  ) { }

  /** GET: Get List of Products*/
  getProducts$(): Observable<Product[]> {
    return this.http.get<Product[]>(this.accessPointUrl)
      .pipe(
        catchError((error) => this._handleError(error))
      );
  }

  /** GET: Product By Id */
  getProduct$(id: string): Observable<Product> {
    const url = `${this.accessPointUrl}/${id}`;
    return this.http.get<Product>(url).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** GET: Product By Partner Id */
  getProductsByPartner$(partnerId: string): Observable<Product[]> {
    const url = `${this.baseUrl}partners/${partnerId}/products`;
    return this.http.get<Product[]>(url).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** PUT: Update Product details and update log file to keep track of changes*/
  updateProduct$(product: Product): Observable<any> {
    return this.http.put(this.accessPointUrl + "/" + product._id, product, {
      headers: new HttpHeaders().set('Authorization', this._authHeader)
    }).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** POST: Create new Product and add changes to log */
  addProduct$(product: Product): Observable<Product> {
    return this.http.post<Product>(this.accessPointUrl, product, {
      headers: new HttpHeaders().set('Authorization', this._authHeader)
    }).pipe(
      tap((newRecord: Product) => {
        //Add new record id to array, to be used to flag new record entries
        this.newRecords.push(newRecord._id)
      }),
      catchError((error) => this._handleError(error))
    );
  }

  /** DELETE: Delete Product Record */
  deleteProduct$(id: string): Observable<any> {
    return this.http.delete(this.accessPointUrl + "/" + id, {
      headers: new HttpHeaders().set('Authorization', this._authHeader)
    }).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  private get _authHeader(): string {
    return `Bearer ${AuthProperties.accessToken$.value}`;
  }

  private _handleError(err: HttpErrorResponse | any): Observable<any> {
    const errorMsg = err.message || 'Error: Unable to complete request.';
    return of(errorMsg);
  }
}
