<!-- Page Heading -->
<div class="d-sm-flex align-items-center justify-content-between mb-4">
  <h1 class="h3 mb-0 text-gray-800">{{pageTitle}}</h1>
  <a routerLink="./new" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">&plus; Add Pickup Point</a>
</div>

<div class="card shadow mb-4">
  <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
    <h6 class="m-0 font-weight-bold text-primary">{{pageTitle}}</h6>
  </div>
  <!-- Search -->
  <!-- <label for="search">Search</label>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <div class="input-group-text">Search</div>
        </div>
        <input
          id="search"
          type="text"
          class="form-control"
          [(ngModel)]="query"
          (keyup)="search()" />
        <span class="input-group-append">
          <button
            class="btn btn-danger"
            (click)="resetQuery()"
            [disabled]="!query">&times;</button>
        </span>
      </div> -->

  <!-- Order List -->
  <div class="card-body">
    <div *ngFor="let pickup of pickupPoints" class="row row-hover mb-4 py-3">

      <div class="col-10">
        <div class="row">

          <div class="col-md-8 col-6 align-self-center" [routerLink]="[ './', pickup._id ]">
            <h5>{{pickup.name}}</h5>
            <p class="mb-0">{{pickup.address}}</p>
          </div>

          <div class="col-md-2 d-none d-md-block align-self-center text-center">
            <!-- <span class="quantity">x {{getNumberOfItems(pickup)}}</span> -->
          </div>

        </div>
      </div>

      <div class="col-2 align-self-center text-right">
        <div class="delete">
          <a data-toggle="modal" data-target="#deleteModal" (click)="deletedItem = pickup"><span
              class="icon-close"></span></a>
        </div>
      </div>
      <hr>
    </div>
  </div>
</div>

<!-- Delete Modal -->
<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteModalLabel">Delete Item(s)</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to delete this item?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="delete(deletedItem)">Yes</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>