<section class="site-section">
  <div class="container vertical-center">
    <div class="text-center mb-4">
      <span class="fa-stack fa-5x">
        <i class="fa fa-circle fa-stack-2x icon-background"></i>
        <i class="fa fa-check fa-stack-1x" style="color: white;"></i>
      </span>
    </div>

    <div class="text-center">
      <h4 class="mb-5">Thank You</h4>
      <h6 class="mb-5">We're excited to partner with you!</h6>
      <p>You will shortly receive an email on the next steps to get you started.</p>
    </div>
  </div>
</section>