import { AlertService, ALERT_EVENT_HANDLERS } from './../../../shared/services/alert.service';
import { AuthService } from './../../../auth/auth.service';
import { Injectable } from '@angular/core';
import { ENV } from 'src/app/core/env.config';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from 'src/app/shared/services/error.service';
import { LogService } from 'src/app/shared/services/log.service';
import { Observable, of, Subject } from 'rxjs';
import { Order } from './order';
import { tap, catchError } from 'rxjs/operators';
import { AuthProperties } from 'src/app/auth/auth-properties';
import { Alert } from 'src/app/shared/classes/alert';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private baseUrl: string = `${ENV.BASE_API}`;
  private accessPointUrl: string = this.baseUrl + 'orders';
  newRecords: any;
  statusOptions = [
    "STARTED",
    "PICKED_UP",
    "READY_TO_DELIVER",
    "ALREADY_DELIVERED",
    "FAILED_DELIVERY",
    "INCOMPLETE"
  ];
  counter: number = 90;
  counter$: Subject<Number> = new Subject();
  intervalId: any;

  constructor(
    private http: HttpClient
  ) { }

  /** GET: Get List of Orders*/
  getOrders$(): Observable<Order[]> {
    return this.http.get<Order[]>(this.accessPointUrl, {
      headers: new HttpHeaders().set('Authorization', this._authHeader)
    }).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** GET: Order By Id */
  getOrder$(id: string): Observable<Order> {
    const url = `${this.accessPointUrl}/${id}`;
    return this.http.get<Order>(url, {
      headers: new HttpHeaders().set('Authorization', this._authHeader)
    }).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** GET: Order By Customer Id */
  getOrderByCustomerId$(customerId: string): Observable<Order> {
    const url = `${this.baseUrl}customers/${customerId}/orders`;
    return this.http.get<Order>(url, {
      headers: new HttpHeaders().set('Authorization', this._authHeader)
    }).pipe(
      catchError((error) => this._handleError(error))
    );
  }

   /** GET: Order By Partner Id */
   getOrdersByPartnerId$(partnerId: string): Observable<Order[]> {
    const url = `${this.baseUrl}partners/${partnerId}/orders`;
    return this.http.get<Order[]>(url).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** GET: Order By Order Number */
  getOrderByOrderNumber$(orderNumber: string): Observable<Order> {
    const url = `${this.baseUrl}orders/${orderNumber}/search`;
    return this.http.get<Order>(url).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** PUT: Update Order details and update log file to keep track of changes*/
  updateOrder$(order: Order): Observable<any> {
    return this.http.put(this.accessPointUrl + "/" + order._id, order, {
      headers: new HttpHeaders().set('Authorization', this._authHeader)
    }).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** POST: Create new Order and add changes to log */
  addOrder$(order: Order): Observable<Order> {
    return this.http.post<Order>(this.accessPointUrl, order).pipe(
      tap(),
      catchError((error) => this._handleError(error))
    );
  }

  /** DELETE: Delete Order Record */
  deleteOrder$(id: string): Observable<any> {
    return this.http.delete(this.accessPointUrl + "/" + id, {
      headers: new HttpHeaders().set('Authorization', this._authHeader)
    }).pipe(
      tap(),
      catchError((error) => this._handleError(error))
    );
  }

  getOrderNumber(orderId: string, orderDate: string) {
    let idSub = orderId.substring(orderId.length - 4, orderId.length);
    return `EMA${orderDate}${idSub}`;
  }

  refresh() {
    this.getOrders$().subscribe(orders => {
      this.newRecords = orders.map(o => o.partnerOrder.filter(detail => detail.orderStatus !== "Order Delivered"));
    });
  }

  setConfirmOrderTimer() {
    this.intervalId = setInterval(() => {
      this.counter = this.counter - 1;
      this.counter$.next(this.counter);
      if (this.counter === 0) clearInterval(this.intervalId)
    }, 1000)
  }

  resetConfirmOrderTimer(){
    this.counter = 90;
    this.intervalId = setInterval(() => {
      this.counter = this.counter - 1;
      this.counter$.next(this.counter);
      if (this.counter === 0) clearInterval(this.intervalId)
    }, 1000)
  }

  stopConfirmOrderTimer(){
    clearInterval(this.intervalId);
  }

  private get _authHeader(): string {
    return `Bearer ${AuthProperties.accessToken$.value}`;
  }

  private _handleError(err: HttpErrorResponse | any): Observable<any> {
    const errorMsg = err.message || 'Error: Unable to complete request.';
    return of(errorMsg);
  }
}
