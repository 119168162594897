import { PartnerRegistrationSuccessComponent } from './pages/partner-registration-success/partner-registration-success.component';
import { CreateAccountComponent } from './shared/modules/account/create-account/create-account.component';
import { PartnerNewComponent } from './admin/pages/partner/partner-new/partner-new.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { TermsComponent } from './pages/terms/terms.component';
import { CallbackComponent } from './pages/callback/callback.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AuthGuard } from './guards/auth.guard';
import { PartnerRegComponent } from './pages/partner-reg/partner-reg.component';
import { AdminGuard } from './guards/admin.guard';

export const APP_ROUTES = [
  { path: '', redirectTo: '/registration', pathMatch: 'full' },
  // { path: 'maintenance', component: MaintenanceComponent },
  { path: 'callback', component: CallbackComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'registration', component: PartnerRegComponent },
  { path: 'registration/success', component: PartnerRegistrationSuccessComponent },
  { path: 'account', component: CreateAccountComponent },
  {
    path: 'verification',
    loadChildren: () => import('./shared/modules/verification/verification.module').then(mod => mod.VerificationModule)
  },
  {
    path: 'signup',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/sign-up/sign-up.module').then(mod => mod.SignUpModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule),
    CanActivate: [AdminGuard]
    // canActivate: [RoleGuard], data: { expectedRole: [ ROLE.ADMIN, ROLE.BAKER ] }
  },
  { path: '**', component: PageNotFoundComponent }
]
