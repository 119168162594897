import { ProfileService } from '../pages/profile/profile.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, concatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private router: Router,
    public _profile: ProfileService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this._profile.isAdmin$.pipe(
      map(isAdmin  => {
        if (isAdmin) {
          return true;
        } 
        else {
          this.router.navigate(['/']);
        }
      })
    );
  }
}
