import { PartnerService } from 'src/app/admin/pages/partner/partner.service';
import { CartItem } from 'src/app/pages/cart/cart-item';
import { LoadingService } from './../../components/loading/loading.service';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { ProfileService } from '../../pages/profile/profile.service';
import { CartService } from 'src/app/pages/cart/cart.service';
import { Location } from './../classes/location';
import { Injectable, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { ErrorService } from './error.service';
import { tap } from 'rxjs/operators';

const MAP_ZOOM = 12;

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  zoom: number;
  private geoCoder = new google.maps.Geocoder();
  latitude: number;
  longitude: number;
  address: string;
  locationChanged$: Subject<boolean> = new Subject();

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private error: ErrorService,
    private cart: CartService,
    private partner: PartnerService
  ) { }

  initGoogleLocationAPI(searchElement: any) {
    this.mapsAPILoader.load().then(
      () => {
        this.geoCoder = new google.maps.Geocoder;

        let autocomplete = new google.maps.places.Autocomplete(searchElement.nativeElement, { componentRestrictions: { country: 'za' } });

        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {

            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();

            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            // Set address, latitude, longitude and zoom to allow user to 
            // fine tune marker after initial location set
            this.address = place.formatted_address;
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = MAP_ZOOM;
            this.locationChanged$.next(true);
            //this.calcDeliveryCost();
          });
        });
      }
    );
  }

  // Get Current Location Coordinates
  setCurrentLocationAsDelivery(event) {

    // Clear any cached location data
    this.resetLocation();

    if (event.target.checked) {
      if ('geolocation' in navigator) {

        navigator.geolocation.getCurrentPosition((position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.zoom = MAP_ZOOM;

          this._setAddress(this.latitude, this.longitude);

          this.locationChanged$.next(true);
          //this.calcDeliveryCost();
        });
      }
    }
  }

  // Set location after marker drag on map
  markerDragEnd($event: google.maps.MouseEvent) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this._setAddress(this.latitude, this.longitude);

    this.locationChanged$.next(true);
    //this.calcDeliveryCost();
  }

  // Get coordinates based on address
  getCoordinates(address: string) {
    this.geoCoder.geocode({ 'address': address }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = MAP_ZOOM;
          this.latitude = results[0].geometry.location.lat();
          this.longitude = results[0].geometry.location.lng();
          this.locationChanged$.next(true);
          //this.calcDeliveryCost();
        } else {
          this.error.handleError('No results found');
        }
      } else {
        this.error.handleError('Geocoder failed due to: ' + status);
      }
    });
  }

  setLocationPin(address: string) {
    if (address) {
      this.setAddress(address);
      this.getCoordinates(address);
    }
  }

  setAddress(address: string) {
    this.address = address;
  }

  // Get address based on latitude and longitude coordinates
  private _setAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = MAP_ZOOM;
          this.address = results[0].formatted_address;
        } else {
          this.error.handleError('No results found');
        }
      } else {
        this.error.handleError('Geocoder failed due to: ' + status);
      }
    });
  }

  calcDeliveryCost() {
    if (this.cart.cart) {
      this.cart.cart.forEach(item => {
        this.partner.getPartner$(item.partnerOrder.partner).subscribe(partner => {

          if(partner.canDeliver) {
            
          }

          // Retrieve delivery distance based on customer location and delivery location
          let pointA = { lat: partner.latitude, lng: partner.longitude };
          let pointB = { lat: this.latitude, lng: this.longitude };
          let deliveryDistance = this.calcDistance(pointA, pointB);
        
          // Calculate delivery cost based on location quotient and base cost
          item.partnerOrder.deliveryFee = +((deliveryDistance / 1000) * partner.costPerKM).toFixed(2);

          // Increment order delivery amount
          this.cart.delivery += item.partnerOrder.deliveryFee;
        })
      })
    }
  }

  getDeliveryCost(pointA, pointB, costPerKM?){ 
    console.log("Distance: " + +((this.calcDistance(pointA, pointB) / 1000)).toFixed(2));
   return +((this.calcDistance(pointA, pointB) / 1000) * costPerKM).toFixed(2);
  }

  calcDistance(pointA, pointB?) {
    var R = 6378137; // Radius of the Earth in metres
    var rlat1 = pointB.lat * (Math.PI / 180); // Convert degrees to radians
    var rlat2 = pointA.lat * (Math.PI / 180); // Convert degrees to radians
    var difflat = rlat2 - rlat1; // Radian difference (latitudes)
    var difflon = (pointA.lng - pointB.lng) * (Math.PI / 180); // Radian difference (longitudes)

    return 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)))
  }

  resetLocation() {
    this.latitude = null;
    this.longitude = null;
    this.address = "";
  }
}
