<section class="site-section">
  <div class="container vertical-center">
    <h4 class="mb-5">Create Account</h4>
    <form [formGroup]="partnerForm" novalidate>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group has-danger">
            <input id="name" type="text" class="form-control" [ngClass]="{'is-invalid': name.hasError('required')}"
              formControlName="name" [ngClass]="{'is-invalid': name.errors && (name.dirty || name.touched)}" required
              placeholder="Name">
            <div class="invalid-feedback" *ngIf="name.hasError('required')">
              <strong>Required</strong> Field
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group has-danger">
            <input id="surname" type="text" class="form-control"
              [ngClass]="{'is-invalid': surname.hasError('required')}" formControlName="surname"
              [ngClass]="{'is-invalid': surname.errors && (surname.dirty || surname.touched)}" placeholder="Surname"
              required>
            <div class="invalid-feedback" *ngIf="surname.hasError('required')">
              <strong>Required</strong> Field
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-group has-danger">
            <input id="email" type="text" class="form-control" [ngClass]="{'is-invalid': email.hasError('email')}"
              formControlName="email" placeholder="Email" required>
            <div class="invalid-feedback" *ngIf="email.hasError('email')">
              <strong>Invalid</strong> Email
            </div>
            <div class="invalid-feedback" *ngIf="email.hasError('required')">
              <strong>Required</strong> Field
            </div>
          </div>
        </div>
      </div>
    </form>
    <form [formGroup]="passwordForm">
      <div class="row align-items-end">
        <div class="col-md-6">
          <div class="form-group">
            <div class="input-group">
              <input [type]="fieldTextType ? 'text' : 'password'" id="password" class="form-control"
                formControlName="password" placeholder="Password" />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i [ngClass]="{'icon-eye-slash': !fieldTextType,
                                  'icon-eye': fieldTextType}" (click)="toggleShowPassword()">
                  </i>
                </span>
              </div>
            </div>

          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group has-danger">
            <div class="input-group">
              <input id="passwordConfirm" [type]="repeatFieldTextType ? 'text' : 'password'" class="form-control"
                [ngClass]="{'is-invalid': passwordForm.hasError('notSame') && (passwordConfirm.dirty || passwordConfirm.touched)}"
                formControlName="passwordConfirm" placeholder="Confirm Password" />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i [ngClass]="{'icon-eye-slash': !repeatFieldTextType,
                                  'icon-eye': repeatFieldTextType}" (click)="toggleRepeatFieldTextType()">
                  </i>
                </span>
              </div>
            </div>

            <div class="invalid-feedback"
              *ngIf="passwordForm.hasError('notSame') && (passwordConfirm.dirty || passwordConfirm.touched)">
              Password does not match
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row mt-4">
      <div class="col">
        <button class="btn btn-primary py-3 mb-4 px-4 btn-block btn-md-inline" type="submit" [disabled]="preLoader.loading">
          <span *ngIf="!preLoader.loading">Submit</span>
          <span *ngIf="preLoader.loading">Loading...</span>
        </button>
      </div>
    </div>
  </div>
</section>