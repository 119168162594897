<div class="site-section">
  <div class="container vertical-center">

    <div class="text-center mb-5">
      <span class="fa-stack fa-5x">
        <i class="fa fa-circle fa-stack-2x icon-background"></i>
        <i class="fa fa-unlock fa-stack-1x" style="color: white;"></i>
      </span>
    </div>

    <div class="row mb-4">
      <div class="col text-center">
        <h4>Enter Code</h4>
        <p>Enter the One Time Password sent to {{numberMask}}</p>
      </div>
    </div>

    <form [formGroup]="codeForm" novalidate (ngSubmit)="checkVerificationCode()">
      <div class="form-row">
        <a class="d-block mb-3 ml-2" style="color: #adb5bd" href="javascript:void(0);" (click)="resendVerificationCode()">Resend Code</a>
      </div>
      <div class="form-row">
        <div class="col">
          <input id="Code1" type="text" class="form-control text-center px-2" formControlName="Code1" required
            minlength="1" maxlength="1" ngxInputRegulate ngxir-pattern="^[0-9 ]*$" (keyup)="tabChange(1)">
        </div>
        <div class="col">
          <input id="Code2" type="text" class="form-control text-center px-2" formControlName="Code2" required
            minlength="1" maxlength="1" ngxInputRegulate ngxir-pattern="^[0-9 ]*$" (keyup)="tabChange(2)">
        </div>
        <div class="col">
          <input id="Code3" type="text" class="form-control text-center px-2" formControlName="Code3" required
            minlength="1" maxlength="1" ngxInputRegulate ngxir-pattern="^[0-9 ]*$" (keyup)="tabChange(3)">
        </div>
        <div class="col">
          <input id="Code4" type="text" class="form-control text-center px-2" formControlName="Code4" required
            minlength="1" maxlength="1" ngxInputRegulate ngxir-pattern="^[0-9 ]*$" (keyup)="tabChange(4)">
        </div>
        <div class="col">
          <input id="Code5" type="text" class="form-control text-center px-2" formControlName="Code5" required
            minlength="1" maxlength="1" ngxInputRegulate ngxir-pattern="^[0-9 ]*$" (keyup)="tabChange(5)">
        </div>
        <div class="col">
          <input id="Code6" type="text" class="form-control text-center px-2" formControlName="Code6" required
            minlength="1" maxlength="1" ngxInputRegulate ngxir-pattern="^[0-9 ]*$" (keyup)="tabChange(6)">
        </div>
      </div>
      <p class="my-4" style="color: red;" *ngIf="isVerified == false">The code entered is incorrect.</p>
      <p class="my-4" *ngIf="hasError">Something went wrong, please try again.</p>
      <div class="form-row mt-4">
        <div class="col">
          <button class="btn btn-primary py-3 mb-4 px-4 btn-block btn-md-inline" type="submit" [disabled]="loading">
            <span *ngIf="!loading">Verify</span>
            <span *ngIf="loading">Loading...</span>
          </button>
        </div>
      </div>
    </form>

  </div>
</div>
