export class Verification {
    to: string;
    channel: string;
    code: string;

    // constructor(verification: any){
    //   this.to = verification.to;
    //   this.channel = verification.channel;
    //   this.code = verification.code;
    // }
  }
  