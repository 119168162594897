import { ProfileService } from './../../pages/profile/profile.service';
import { CustomerService } from './../../admin/pages/customer/customer.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isCollapsed = true;
  isAuthorised = false; 

  constructor(
    public auth: AuthService,
    public customer: CustomerService,
    public profile: ProfileService  ) { }

  ngOnInit(): void {
    $(function () {
      'use strict'

      $('[data-toggle="offcanvas"]').on('click', function () {
        $('.offcanvas-collapse').toggleClass('open')
      })
    });

    this.profile.userRole$.subscribe(role => {
      if(role == 'Admin' || role == 'Baker') {
        this.isAuthorised = true;
      }
    })
  }

  closeSideBar(){
    $(function () {
      'use strict'
        $('.offcanvas-collapse').toggleClass('open')
    });

    this.isCollapsed =! this.isCollapsed; 
  }

  getApplicationRoot() {
    return `${window.location.origin}`;
  }
}
