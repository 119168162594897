import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingComponent } from './../../../components/loading/loading.component';
import { ConfirmComponent } from './../../modals/confirm/confirm.component';
import { DeleteComponent } from './../../modals/delete/delete.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentModule } from 'ngx-moment';
import { MaterialModule } from '../material/material.module';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '@angular/cdk/layout';
import { NgxPictureModule } from 'ngx-picture';
import { PickUpPointIndexComponent } from 'src/app/admin/pages/pick-up-point/pick-up-point-index/pick-up-point-index.component';
import { FileUploadModule } from 'ng2-file-upload';
import { CloudinaryModule } from '@cloudinary/angular-5.x';
import { ImagePreviewDirective } from '../../directives/image-preview.directive';
import { ngxInputRegulateModule } from 'ngx-input-regulate';
import { ProductCatalogueComponent } from 'src/app/admin/pages/product/product-catalogue/product-catalogue.component';


@NgModule({
  declarations: [
    DeleteComponent,
    ConfirmComponent,
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    PickUpPointIndexComponent,
    ImagePreviewDirective,
    ProductCatalogueComponent 
  ],
  imports: [
    CommonModule,
    MomentModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    LayoutModule,
    NgxPictureModule,
    FileUploadModule,
    CloudinaryModule,
    ngxInputRegulateModule
  ],
  exports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    LoadingComponent, 
    MomentModule,
    MaterialModule,
    RouterModule, 
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    NgxPictureModule,
    FileUploadModule,
    CloudinaryModule,
    ImagePreviewDirective,
    ngxInputRegulateModule,
    ProductCatalogueComponent
  ]
})
export class SharedModule { }
