import { PushNotificationsService } from 'src/app/shared/services/push-notifications.service';
import { AuthProperties } from './../../../auth/auth-properties';
import { AuthService } from 'src/app/auth/auth.service';
import { Injectable } from '@angular/core';
import { ENV } from 'src/app/core/env.config';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from 'src/app/shared/services/error.service';
import { LogService } from 'src/app/shared/services/log.service';
import { Observable, Subject, of } from 'rxjs';
import { Customer } from './customer';
import { tap, catchError, retry } from 'rxjs/operators';
import { httpOptions } from 'src/app/core/http.options';
import { ThrowStmt } from '@angular/compiler';
import { json } from 'body-parser';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private baseUrl: string = `${ENV.BASE_API}`;
  private accessPointUrl: string = this.baseUrl + 'customers';
  newRecords = [];
  customer: Customer;
  customerId$: Subject<string> = new Subject();

  constructor(
    private http: HttpClient
  ) { }

  /** GET: Get List of Customers*/
  getCustomers$(): Observable<Customer[]> {
    return this.http.get<Customer[]>(this.accessPointUrl, {
      headers: new HttpHeaders().set('Authorization', this._authHeader)
    }).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** GET: Customer By Id */
  getCustomer$(id: string): Observable<Customer> {
    const url = `${this.accessPointUrl}/${id}`;
    return this.http.get<Customer>(url).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** GET: Customer By Auth Id */
  getCustomerByUserId$(authId: string): Observable<Customer> {
    const url = `${this.accessPointUrl}/${authId}/auth`;
    return this.http.get<Customer>(url).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** PUT: Update Customer details and update log file to keep track of changes*/
  updateCustomer$(customer: Customer): Observable<any> {
    // Update date modified
    customer.dateModified = new Date();

    return this.http.put(this.accessPointUrl + "/" + customer._id, customer).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** POST: Create new Customer and add changes to log */
  addCustomer$(customer: Customer): Observable<Customer> {
    // Set default dates
    customer.dateCreated = new Date();
    customer.dateModified = new Date();

    return this.http.post<Customer>(this.accessPointUrl, customer).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  /** DELETE: Delete Customer Record */
  deleteCustomer$(id: string): Observable<any> {
    return this.http.delete(this.accessPointUrl + "/" + id, {
      headers: new HttpHeaders().set('Authorization', this._authHeader)
    }).pipe(
      catchError((error) => this._handleError(error))
    );
  }

  setCustomer(userId: string) {
    this.getCustomerByUserId$(userId).subscribe(customer => {
      if (customer) {
        this.customer = customer;
      }
    });
  }

  addPushSubscriber(sub: any, customer: Customer) {
    // Set push subscription object
    customer.pushSubscription = sub;

    // Update customer in database
    this.updateCustomer$(customer).subscribe();
  }

  removePushSubscriber(customer: Customer) {
    // Reset push subscription
    customer.pushSubscription = {};

    // Update customer in database
    this.updateCustomer$(customer).subscribe();
  }

  private get _authHeader(): string {
    return `Bearer ${AuthProperties.accessToken$.value}`;
  }

  private _handleError(err: HttpErrorResponse | any): Observable<any> {
    const errorMsg = err.message || 'Error: Unable to complete request.';
    return of(errorMsg);
  }
}
