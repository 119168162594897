import { VerificationService } from './../verification.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verification-success',
  templateUrl: './verification-success.component.html',
  styleUrls: ['./verification-success.component.css']
})
export class VerificationSuccessComponent implements OnInit {

  constructor(
    private router: Router,
    private verification: VerificationService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.router.navigate([this.verification.targetRoute || '/home']);
    }, 6000);
  }

}
