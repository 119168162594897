import { PartnerService } from './../partner.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/auth/auth.service';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import { PasswordErrorStateMatcher } from 'src/app/shared/classes/password-error-state-matcher';
import { LocationService } from 'src/app/shared/services/location.service';
import { Partner } from '../partner';
import { tap, map, startWith } from 'rxjs/operators';
import { Order } from '../../order/order';
import { Profile } from 'src/app/pages/profile/profile';
import { USER_CONFIG } from 'src/app/core/user.config';
import { MessageService } from 'src/app/shared/services/message.service';
import { AppUtils } from 'src/app/shared/classes/utils';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-partner-new',
  templateUrl: './partner-new.component.html',
  styleUrls: ['./partner-new.component.css']
})
export class PartnerNewComponent implements OnInit {

  pageTitle = 'Emabhansini | Partner Registration';
  @ViewChild('address') searchElement: ElementRef;
  matcher = new PasswordErrorStateMatcher();
  fieldTextType: boolean;
  repeatFieldTextType: boolean;
  readTerms: boolean;
  partnerForm = this.fb.group({
    userId: [''],
    name: [null, Validators.required],
    surname: [null, Validators.required],
    contact: [null, [
      Validators.required,
      Validators.maxLength(10),
      Validators.pattern('[0-9]{10}'),
      Validators.minLength(10)]
    ],
    email: [null, [
      Validators.email,
      Validators.required]
    ],
    businessName: ['', Validators.required],
    canDelivery: [null, Validators.required],
    deliveryAreas: [null, Validators.required],
    passwordForm: this.fb.group({
      password: [''],
      passwordConfirm: ['']
    }, { validator: AppUtils.checkPasswords })
  })
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = ['Lemon'];
  allFruits: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;



  constructor(
    private fb: FormBuilder,
    private title: Title,
    public profile: ProfileService,
    public auth: AuthService,
    public location: LocationService,
    public preLoader: LoadingService,
    public partner: PartnerService,
    private message: MessageService
  ) {

    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      map((fruit: string | null) => fruit ? this._filter(fruit) : this.allFruits.slice()));
  }

  ngOnInit(): void {
    // Set web app tab title
    this.title.setTitle(this.pageTitle);
  }

  ngAfterViewInit() {
    // Initialise google location API
    this.location.initGoogleLocationAPI(this.searchElement);
  }

  // Create auth0 account and add to partner record in database
  createProfile() {

    // Check if email address is associated with another account
    if (!this._profileExists()) {

      let profile = new Profile(this.partnerForm.value, null, this.passwordForm.value);
      profile.user_metadata.address = this.location.address;
      profile.connection = USER_CONFIG.USER_API_CONNECTION;

      this.profile
        .addProfile$(profile).pipe(
          tap(profile => {
            let partner = new Partner(
              this.partnerForm.value,
              this.location.address,
              this.location.latitude,
              this.location.longitude
            );
            this.partner.addPartner$(partner).subscribe();
          })
        )
        .subscribe(_ => this.message.add('Your profile has been created'));

    } else {
      this.message.add('This email is associated with another account')
    }
  }

  toggleShowPassword() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleRepeatFieldTextType() {
    this.repeatFieldTextType = !this.repeatFieldTextType;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.fruits.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.fruitCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFruits.filter(fruit => fruit.toLowerCase().includes(filterValue));
  }

  private _profileExists() {
    // this.partnerForm.get('email').value;
    // this.email.value
    return false;
  }

  get name() {
    return this.partnerForm.get('name');
  }

  get surname() {
    return this.partnerForm.get('surname');
  }

  get email() {
    return this.partnerForm.get('email');
  }

  get contact() {
    return this.partnerForm.get('contact');
  }

  get businessName() {
    return this.partnerForm.get('businessName');
  }

  get canDeliver() {
    return this.partnerForm.get('canDeliver');
  }

  get deliveryAreas() {
    return this.partnerForm.get('deliveryAreas');
  }

  get passwordForm() {
    return this.partnerForm.controls.passwordForm;
  }

  get password() {
    return this.partnerForm.controls.passwordForm.get('password');
  }

  get passwordConfirm() {
    return this.partnerForm.controls.passwordForm.get('passwordConfirm');
  }

}
